import Vue from 'vue'
import VueRouter from 'vue-router'
import indexpage from '../views/pc/index.vue'
import indexpagemb from '../views/mobile/index.vue'
import newsindexmb from '../views/mobile/news/newsindex.vue'
import newscontext from '../views/mobile/news/newscontext.vue'
import linkpage from '../views/mobile/linkpage.vue'

Vue.use(VueRouter)

const routerpc = [
  {
    path: '/',
    name: 'indexpage',
    component: indexpage
  },
  {
    path: '/linkpage',
    name: 'linkpage',
    component: linkpage
  }
  // {
  //   path: '/about',
  //   name: 'about',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]
const routermb = [
  {
    path: '/',
    name: 'indexpage',
    component: indexpagemb
  },
  {
    path: '/newsindexmb',
    name: 'newsindexmb',
    component: newsindexmb
  },
  {
    path: '/newscontext',
    name: 'newscontext',
    component: newscontext,
  },
  {
    path: '/linkpage',
    name: 'linkpage',
    component: linkpage
  }
]
let routes = []
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routermb
} else {
  routes = routerpc

}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    console.log('移动端不缩放')
    const baseSize = 16
    // // 设置 rem 函数
    function setRem() {
      // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 375
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
    }
    setRem()
} else {                                                                                                                                 
  router.afterEach((to, from) => {
    // 基准
    const baseSize = 16
    // 设置 rem 函数
    function setRem() {
      // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
      const scale = document.documentElement.clientWidth / 1920
      // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
      document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
    }
    setRem()
  })
}
export default router
