<template >
  <div class="h-[100vh] bg-[rgb(22,24,25)]">
    <menupage></menupage>
    <div class="absolute top-[4.625rem] left-[21.625rem]" @click="onback()">
      <img src="../../../assets/mobile/news/back.png" class="w-[1rem] h-[1.125rem]" alt="">
    </div>
    <div class="w-full h-[25.3125rem]">
      <img src="../../../assets/mobile/news/headbg.png" alt="">
    </div>
    <div class="bg-[rgb(22,24,25)] w-full h-[26.875rem]  top-[14.8125rem] absolute">
      <!-- 标题 -->
      <div class=" flex justify-center mt-[1.6875rem] ">
        <span class="title w-[23.4375rem]  px-[2rem] flex justify-center">
          {{ title }}
        </span>
      </div>
      <!-- 内容 -->
      <div class="w-full h-full flex justify-center bg-[rgb(22,24,25)] mt-[1.625rem]">
        <div class="bg-[rgb(22,24,25)] mt-[1.625rem]  w-full ">
          <p v-html="content" class="bg-[rgb(22,24,25)]  text-[#FFFFFF] text-[.5625rem] px-[5%] pb-[2.125rem]"></p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import menupage from '../../../components/mobile/menupage.vue'
import axios from 'axios';
export default {
  name: 'newsContext',
  data() {
    return {
      content: '',
      title: ''
    }
  },
  components: {
    menupage
  },
  created() {
    this.getinit()
  },
  methods: {
    onback() {
      this.$router.push({
        name: 'newsindexmb', query: {
          merchID: this.$route.query.merchID,
          subchannel: this.$route.query.subchannel,
        }
      })
      // window.history.go(-1)
    },
    getinit() {
      // fetch(`https://sdk.nextjoy.com/api/information/GetArticle?pubkey=${pubkey}&article_id=${article_id}`)
      // .then(res=>{

      // })
      let id = this.$route.query.article_id
      console.log(id)
      axios.get('https://sdk.nextjoy.com/api/information/GetArticle', {
        params: {
          pubkey: "3253942b24c7ad7eca566bf92696e1a6",
          article_id: id
        }
      }).then(res => {
        if (res.status == 200) {
          this.content = res.data.data.content
          this.title = res.data.data.title
        }
        console.log(res)
      })
    }
  }
}
</script>

<style scoped>
.title {
  /* width: 197px; */
  height: .8125rem;
  font-size: .8125rem;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #FFFFFF;
  line-height: .875rem;
}
</style>
