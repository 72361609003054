<template>
  <div class="w-full h-full clearfix relative">
    <div class=" mt-[7.6875rem] w-full flex justify-center">
      <img src="../../assets/mobile/role/zyjs.png" class="w-[7.25rem] h-[1.6875rem]" alt="">
    </div>
    <div class="absolute">
      <!-- <img src="../../assets/mobile/role" alt=""> -->
      <img :src="rolelist[0].role" class="w-full h-[23.9375rem]" alt="">
    </div>
    <!-- 角色立绘 -->
    <div class="absolute top-[25.5625rem] left-[10.4375rem]">
      <img :src="rolelist[0].icon"  class="w-[2.625rem] h-[2.5625rem]" alt="">
    </div>
    <!-- 角色名称 -->
    <div class="absolute top-[29.625rem] left-[9.375rem] ">
      <span class="text-[#F7EDDD] text-[.75rem] roeltext">{{ rolelist[0].name }}</span>
    </div>
    <!-- 职业介绍 -->
    <div class="w-[17.375rem] h-[2.0625rem] absolute top-[31.5rem] left-[3.125rem]">
      <span class="text-[.75rem] text-[#ffffff]">{{ rolelist[0].content }}</span>
    </div>
    <!-- 角色切换 -->
    <div class="top-[36.25rem] absolute">
      <div class="ml-[.9375rem]" @click="onright()">
        <img src="../../assets/mobile/role/left.png" class="w-[2.4375rem] h-[4.5625rem]" alt="">
      </div>
    </div>
    <div class="bg-[rgba(0,0,0,0.6)] rounded-[50%] w-[3.375rem] h-[3.3125rem] top-[36.875rem] left-[4.4375rem] absolute z-[2]  " @click="onright()"></div>
    <div class="top-[36.875rem] left-[4.4375rem] absolute "    :class="{ textanimation: disabled }">
      <img :src="rolelist[5].imgpage" class="w-[3.375rem] h-[3.3125rem]" alt="">
    </div>
    <div class="top-[36.875rem] left-[10.125rem] absolute">
      <img :src="rolelist[0].imgpage" class="w-[3.375rem] h-[3.3125rem]" alt="">
    </div>
    <div class="bg-[rgba(0,0,0,0.6)] rounded-[50%] w-[3.375rem] h-[3.3125rem] top-[36.875rem] left-[15.6875rem] absolute z-[2] "  @click="onleft()"></div>
    <div class="top-[36.875rem] left-[15.6875rem] absolute"    :class="{ textanimation: disabled }">
      <img :src="rolelist[1].imgpage" class="w-[3.375rem] h-[3.3125rem]" alt="">
    </div>
    <div class="top-[36.25rem] absolute left-[20.25rem]">
      <div class="" @click="onleft()">
        <img src="../../assets/mobile/role/right.png" class="w-[2.4375rem] h-[4.5625rem]" alt="">
      </div>
    </div>
    <div class="absolute z-[1] top-[38.5rem]">
      <img src="../../assets/mobile/role/hr.png" class="w-full h-[.125rem]" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'rolePage',
  data() {
    return {
      // amnation
      disabled: false,
      rolelist: [
        {
          name: '狂战士 加里安',
          content: '背负亡女之仇的黑暗战士，使用斧头的狂战士拥有极高的攻击力，擅长近身搏杀，能迅速接近并消灭敌人，也能引诱敌人并施展猛烈一击。',
          imgpage: require('../../assets/pc/role/userkz.png'),
          role: require('../../assets/mobile/role/kz.png'),
          icon: require('../../assets/pc/role/kzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/kzs.mp4'
        },
        {
          name: '守护骑士 泰文',
          content: '寻找丢失记忆的守护骑士，使用锤子和盾牌作为武器，拥有极高防御力，在降低敌人伤害的同时，能将敌人聚集到一起并发动猛攻。',
          imgpage: require('../../assets/pc/role/usershqs.png'),
          role: require('../../assets/mobile/role/sqs.png'),
          icon: require('../../assets/pc/role/shqsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/shqs.mp4'
        },
        {
          name: '魔法师 丽娅',
          content: '痴迷于神秘魔法的天才魔法师，使用法环作为武器，擅长远程攻击，使用着曾经不被允许的魔法进行战斗，聚气之后可以用更强的攻击打倒敌人。',
          imgpage: require('../../assets/pc/role/userfs.png'),
          role: require('../../assets/mobile/role/fs.png'),
          icon: require('../../assets/pc/role/fsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/mfs.mp4'
        },
        {
          name: '潜行者 内梅斯',
          content: '深受误解却依然正义的潜行者，使用短剑作为武器，是近身灵活缠斗的专家，能用各种技能削弱敌人，然后敏捷地发动攻击。',
          imgpage: require('../../assets/pc/role/userqxz.png'),
          role: require('../../assets/mobile/role/qxz.png'),
          icon: require('../../assets/pc/role/qxzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/qxz.mp4'
        },
        {
          name: '弓箭手 蒂埃拉',
          content: '劫富济贫的侠盗团团长养女，专精于弓箭，擅长灵活的远程战斗，能迅速回避敌人的攻击，并敏锐地使用箭矢反击。',
          imgpage: require('../../assets/pc/role/usergjs.png'),
          role: require('../../assets/mobile/role/gjs.png'),
          icon: require('../../assets/pc/role/gjsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/gjs.mp4'
        },
        {
          name: '学者 赛尔菲',
          content: '用清晰的头脑研究知识的学者，使用4种召唤术形态的技能，可近战也可远攻，根据技能组合展开全方位的攻击是她的特色。',
          imgpage: require('../../assets/pc/role/userxz.png'),
          role: require('../../assets/mobile/role/xz.png'),
          icon: require('../../assets/pc/role/xzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/xz.mp4'
        }
      ]
    }
  },
  components: {

  },
  methods:{
    onleft() {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 300);
      let removedItem = this.rolelist.shift();
      console.log(removedItem)
      this.rolelist.push(removedItem)
      // var circle = document.getElementById('circle');
      // var currentRotation = this.getRotation(circle);
      // // var currentRotation = 0
      // circle.style.transform = `rotateZ(${currentRotation - 60}deg)`;

    },
    onright() {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 300);
      let removedItem = this.rolelist.pop();
      console.log(removedItem)
      this.rolelist.unshift(removedItem)
      // var circle = document.getElementById('circle');
      // var currentRotation = this.getRotation(circle);
      // // var currentRotation = 0
      // circle.style.transform = `rotateZ(${currentRotation + 60}deg)`;
    },
  }
}
</script>

<style scoped>
/* 角色动画 */
.textanimation {
  animation-name: textdh;
  animation-duration: 2s;
}

@keyframes textdh {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.roeltext{
  font-family: 'zihun95hao-shoukesong';
  font-weight: normal; /* 或 bold，或你需要的字重值 */
}
.clearfix::before {
    content: '';
    display: table;
}
</style>
