<template>
  <div class="bg-black">
    <!-- 引导页 -->
    <div class="z-[1045] fixed w-[100vw] h-[100vh] bg-white" v-if="ydmodel">
      <img src="../../assets/mtydpage.jpg" alt="">
    </div>
    <!-- 视频弹框 -->
    <div class="fixed z-[1001] bg-black w-[100vw] h-[100vh]" v-if="isvideobox">
      <div class="flex justify-center items-center h-full w-full">
        <div class="text-[#ffffff]  absolute top-[25%] z-[2] ml-[90vw] cursor-pointer" @click="isvideobox = false">
          <img src="../../assets/pc/role/close.png" class="w-[1.5625rem] h-[1.5625rem]" alt="">
        </div>
        <!-- <div class="videoclose z-[10]  absolute top-[25%] ml-[90vw] " @click="isvideo = false"></div> -->
        <div class=" mt-[-5rem] z-[1]">
          <!-- :poster="videoimg[index]" -->
          <video class="video-background-alert " controls preload="auto" loop playsinline autoplay :src=xzvideo.video
            tabindex="-1" muted="muted">
          </video>
        </div>
      </div>
    </div>
    <div class="fixed z-[1001] bg-black w-[100vw] h-[100vh]" v-show="isimgbox">
      <div class="flex justify-center items-center h-full w-full">
        <div class="text-[#ffffff]  absolute top-[20%] z-[2] ml-[90vw] cursor-pointer" @click="isimgbox = false">
          <img src="../../assets/pc/role/close.png" class="w-[1.5625rem] h-[1.5625rem]" alt="">
        </div>
        <div class="z-[1]">
          <img :src="xzvideo.img" alt="">
        </div>
      </div>
    </div>
    <div class="mrzxbg w-[8.25rem] h-[3.875rem] fixed z-[1000] top-[4.75rem] right-[.625rem] " v-if="ismrzxbol">
     <div class="relative">
        <!-- logo -->
        <div class=" absolute top-[-0.6875rem] left-[.75rem]">
          <img src="../../assets/pc/xz/masterbut/mrzxlogo.png" class="w-[1.3125rem] h-[1.5rem]" alt="">
        </div>
        <!-- 关闭 -->
        <div class="absolute top-[-0.5625rem] right-[.5rem] cursor-pointer" @click="ismrzxbol = false">
          <img src="../../assets/pc/xz/masterbut/mrzxclose.png" class="w-[1.25rem] h-[1.25rem]" alt="">
        </div>
        <div class="absolute top-[1.28rem] left-[5.325rem]">
          <img src="../../assets/pc/xz/masterbut/ewm.png" class="w-[2.38rem] h-[2.35rem]" alt="">
        </div>
     </div>
    </div>
    <div class="fixed z-[1000]  top-[2.75rem] right-1 " v-if="!ismrzxbol" @click="ismrzxbol = true">
      <img src="../../assets/pc//xz/masterbut/logodt.gif" class="w-[7rem] h-[7rem]" alt="">
    </div>
    <menupage></menupage>
    <!-- 左侧菜单 -->
    <div class="fixed w-full h-[100vh] bg-[rgba(0,0,0,0.5)] z-[900]" v-show="ismenu" @click="ismenu = false">
      <div class="h-[3.5625rem] w-full"></div>
      <div class="menubg w-[16.875rem] h-full flex flex-col items-center gap-[.3125rem]">
        <div v-for="(item, index) in menulist" class=" w-[17.4375rem] h-[4.875rem] flex justify-center items-center"
          @click.stop="onmenufun(item, index)">
          <span class="menutext w-full flex justify-center"
            :class="propsmenuindex == index ? 'opacity-[1]' : 'opacity-[0.5]'">
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
    <!-- 菜单按钮 -->
    <div class="fixed top-[4.75rem] left-[.9375rem] z-[1000] " @click="ismenu = true">
      <img src="../../assets/mobile/menubut.png" class="w-[1.1875rem] h-[.875rem]" alt="" @click="ismenu = true">
    </div>
    <full-page :options="options" ref="fullpage">
      <!-- 首页 -->
      <div class="bg_01 flex w-full flex-col justify-center  items-center relative section">
        <!-- 标语 -->
        <div class="mt-[27.6875rem]">
          <img src="../../assets/mobile/slogan.png" class="w-[18.6875rem] h-[4.375rem]" alt="">
        </div>
        <!-- 子标题 -->
        <div>
          <div class="ztitlebg w-[17.5rem] h-[1.0625rem] flex justify-center items-center">
            <span class="ztitletext">暗黑魔幻生存MMORPG手游</span>
          </div>
        </div>
        <!-- 下载 -->
        <div class="flex mt-[.3125rem] justify-center items-center  h-[3rem]">
          <img src="../../assets/mobile/download.png" class="w-[9.6875rem] h-[2.875rem]" @click="ongamedownload()" alt="">
          <img src="../../assets/mobile/xz/tap.png" class="w-[7.9375rem] h-[1.6875rem] mt-[.2rem] ml-[.1875rem]"
            @click="clickgotappath()" alt="">
        </div>
        <!-- scroll -->
        <div class="flex justify-center mt-[1rem]">
          <img src="../../assets/mobile/scroll.png" class="w-[2.375rem] h-[2.0625rem]]" alt="">
        </div>
      </div>
      <!-- 新闻页面 -->
      <div class="bg_02 section clearfix bg-[rgba(24,24,24,0.1)]">
        <!-- 标题 -->
        <div class="flex justify-center mt-[6.625rem]">
          <img src="../../assets/mobile/zx.png" class="w-[3.5rem] h-[1.6875rem]" alt="">
        </div>
        <!-- 轮播图 -->
        <div class="mt-[.5625rem] flex flex-col h-full items-center">
          <div class="w-[22.5rem]  flex justify-center">
            <van-swipe class="my-swipeone" :autoplay="3000" @change="handleChangeone" indicator-color="white"
              :show-indicators="false" ref="swiperone">
              <van-swipe-item v-for="(item, index)  in lblist" class="flex justify-center">
                <div class="w-[21.875rem] h-[11.3125rem]">
                  <img :src="item.path" class="object-fill w-full h-full" alt="">
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <!-- 控制菜单 -->
          <div class=" flex justify-center" v-if="true">
            <div class="cursor-pointer" v-for="(item, index) in  lblist.length" @click="switchToSecondItemone(index)">
              <img src="@/assets/pc/newsimg/no.png" class="w-[1.1875rem] h-[1.1875rem]" alt=""
                v-if="index == activeIndexone">
              <div class="w-[1.1875rem] h-[1.1875rem] flex justify-center items-center">
                <img src="@/assets/pc/newsimg/off.png" class="w-[.75rem] h-[.6875rem]" alt=""
                  v-if="index != activeIndexone">
              </div>
            </div>
          </div>
          <!-- 新闻列表 -->
          <div class="w-[20.5rem] z-[0] relative h-[14.6875rem]">
            <div class="flex justify-start mt-[.6875rem]">
              <div @click="onnewsfun(item, index)" v-for="(item, index) in newslist"
                class="w-[4.9375rem]  flex justify-center flex-col items-center relative cursor-pointer">
                <span class="titletext text-[1.125rem] "
                  :class="newsindex == index ? 'text-[#E0C595]' : 'text-[#7F7D77]'">
                  {{ item.name }}
                </span>
                <img v-if="newsindex == index" src="@/assets/pc/newsimg/hover.png"
                  class="absolute w-[4.9375rem] h-[3.5rem] top-[.27rem] object-contain" alt="">
              </div>
              <!-- <div @click="onshowmore()" class="mt-2 right-11 absolute cursor-pointer"><span
                  class="text-[#7F7D77] text-[1.125rem]">查看更多</span></div> -->
            </div>
            <!-- 下划线 -->
            <div class="flex justify-center mt-[.7rem]">
              <div><img src="@/assets/pc/newsimg/hr.png" class="w-[39.25rem] h-[.125rem]" alt=""></div>
            </div>
            <div class="mt-[.625rem] flex gap-[.5rem] justify-center flex-col items-center">
              <div v-for="item in newscontent.slice(0, 5)" @click="shownewstext(item)">
                <div class="w-[20.5rem] flex justify-between text-[#908D88] text-[.75rem] cursor-pointer">
                  <div class=" w-[17.15rem] overflow-hidden whitespace-nowrap text-ellipsis mx-1">{{ item.title }}</div>
                  <div class="w-[2.3rem] text-[.75rem] mx-1 "
                    v-if="item.start_time && typeof item.start_time === 'string'">
                    {{ item.start_time.slice(5, 10) }}
                  </div>
                </div>
                <div class="mt-1">
                  <img src="@/assets/pc/newsimg/contenthr.png" class="w-[39.25rem] h-[.0625rem]" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- 查看更多 -->
          <div class="mt-[1%]" @click="gonewsfun()">
            <img src="../../assets/mobile/morebut.png" class="w-[6.0625rem] h-[2.75rem]" alt="">
          </div>
        </div>
      </div>
      <!-- 角色信息 -->
      <div class="bg_03 section w-full h-full">
        <rolepage></rolepage>
      </div>
      <!-- 视听 -->
      <div class="bg_04 section">
        <div class="relative">
          <!-- 标题 -->
          <div class="absolute top-[7.1875rem] left-[8.0625rem]">
            <img src="../../assets/mobile/st/sttitle.png" class="w-[7.25rem] h-[[1.6875rem]]" alt="">
          </div>
          <!-- 内容 -->
          <!-- 视频 -->
          <div class="flex flex-col items-center">
            <div class="w-[21.75rem] h-[10.5rem] bg-black mt-[10.1375rem]  flex justify-center items-center relative">
              <img src="../../assets/mobile/st/play.png" class="z-[2] w-[2.1875rem] h-[2.1875rem]" alt=""
                @click="onplayvidow(stvideolist[0].video)">
              <img :src="stvideolist[0].path" class="absolute w-full h-full z-[1]" alt="">
            </div>
          </div>
          <!-- 图片 -->
          <div class="w-full flex justify-center mt-[.4375rem]">
            <div class="w-[10.6875rem] h-[7.125rem] bg-black" @click="onboximg(stimglist[0].path)">
              <img :src="stimglist[0].path" class="w-full h-full object-cover  object-top" alt="">
            </div>
            <div class="w-[10.6875rem] h-[7.125rem] bg-black ml-[.375rem]" @click="onboximg(stimglist[1].path)">
              <img :src="stimglist[1].path" class="w-full h-full object-cover object-top" alt="">
            </div>
          </div>
          <div class="w-full flex justify-center mt-[.4375rem]">
            <div class="w-[10.6875rem] h-[7.125rem] bg-black" @click="onboximg(stimglist[2].path)">
              <img :src="stimglist[2].path" class="w-full h-full object-cover object-top" alt="">
            </div>
            <div class="w-[10.6875rem] h-[7.125rem] bg-black ml-[.375rem]" @click="onboximg(stimglist[3].path)">
              <img :src="stimglist[3].path" class="w-full h-full object-cover object-top" alt="">
            </div>
          </div>
          <div class="flex justify-center mt-[2.2rem]" @click="oncheckfun()">
            <img src="../../assets/mobile/st/rfsbut.png" class="w-[1.6875rem] h-[1.5rem]" alt="">
          </div>
        </div>
      </div>
      <!-- 游戏特色 -->
      <div class="bg_05 section relative clearfix">
        <div class="absolute top-[7.25rem] left-[7.6875rem]">
          <img src="../../assets/mobile/gamets/title.png" class="w-[8rem] h-[2.375rem]" alt="">
        </div>
        <div class="absolute lfet-[.625rem] top-[22.25rem]" @click="ontscontext('left')">
          <img src="../../assets/mobile/gamets/left.png" class="w-[2.25rem] h-[3.6  25rem]" alt="">
        </div>
        <div class="absolute right-[.625rem] top-[22.25rem]" @click="ontscontext('right')">
          <img src="../../assets/mobile/gamets/right.png" class="w-[2.25rem] h-[3.625rem]" alt="">
        </div>
        <!-- div -->
        <div class="w-[16.125rem] h-[27.875rem] bg-black absolute top-[10.1875rem] left-[3.6875rem]">
          <van-swipe class="my-swipe" :autoplay="0" @change="handleChange" indicator-color="white"
            :show-indicators="false" ref="swiper">
            <van-swipe-item v-for="item in lbtslist" class="flex justify-center">
              <div class="w-full h-full">
                <img :src="item.path" class="  object-fill w-full h-full" alt="">
              </div>
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 游戏特色 -->
        <div class="  flex justify-center   mt-[38.6875rem]" v-if="true">
          <div class="cursor-pointer" v-for="(item, index) in  5" @click="switchToSecondItem(index)">
            <img src="@/assets/pc/newsimg/no.png" class="w-[1.1875rem] h-[1.1875rem]" alt="" v-if="index == activeIndex">
            <div class="w-[1.1875rem] h-[1.1875rem] flex justify-center items-center">
              <img src="@/assets/pc/newsimg/off.png" class="w-[.75rem] h-[.6875rem]" alt="" v-if="index != activeIndex">
            </div>
          </div>
        </div>
      </div>
      <!-- 社群 -->
      <div class="bg_06 section relative ">
        <div class="absolute left-[9.5625rem] top-[5.0625rem]">
          <img src="../../assets/mobile/sq/title.png" class="4.375rem h-[2.75rem]" alt="">
        </div>
        <div class="absolute left-[7.8125rem] top-[10rem] ">
          <img src="../../assets/mobile/sq/dy.png" class="w-[2.25rem] left-[4.25rem]" alt="" @click="golinkfun('dy')">
        </div>
        <div class="absolute left-[13.375rem] top-[10rem] ">
          <img src="../../assets/mobile/sq/wb.png" class="w-[2.25rem] left-[4.25rem]" alt="" @click="golinkfun('wb')">
        </div>
        <div class="absolute left-[6.875rem] top-[15.5rem] ">
          <img src="../../assets/mobile/sq/bl.png" class="w-[4.125rem] left-[4.25rem]" alt="" @click="golinkfun('bl')">
        </div>
        <div class="absolute left-[12.6875rem] top-[15.5rem] ">
          <img src="../../assets/mobile/sq/tap.png" class="w-[3.6875rem] left-[4.4375rem]" alt=""
            @click="golinkfun('tap')">
        </div>
        <div class="absolute left-[7.8125rem] top-[21rem] ">
          <img src="../../assets/mobile/sq/wx.png" class="w-[2.25rem] left-[4.25rem]" alt="" @click="golinkfun('wx')">
        </div>
        <div class="absolute left-[13.375rem] top-[21rem] ">
          <img src="../../assets/mobile/sq/qq.png" class="w-[2.25rem] left-[4.25rem]" alt="" @click="golinkfun('qq')">
        </div>
        <div class="absolute left-[6.82rem] top-[26.1375rem] w-[4.25rem] h-[4.375rem]">
          <img src="../../assets/mobile/sq/mrzx.png" class=" w-[4.25rem] h-[4.375rem]" alt="" @click="golinkfun('mrzx')">
        </div>
        <div class="w-full flex justify-center h-[9rem] bg-[rgba(0,0,0,0.6)] absolute bottom-0">
          <footerpage class="mt-[.625rem] mb-[.625rem]"></footerpage>
        </div>

      </div>
    </full-page>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs'
import menupage from '../../components/mobile/menupage.vue'
import rolepage from '../../components/mobile/role.vue'
import footerpage from '../../components/mobile/mobilefooter/index.vue'
export default {
  name: 'indexpagemb',
  created() {
    this.getlist()
  },
  components: {
    menupage,
    rolepage,
    footerpage
  },
  data() {
    return {
      ismrzxbol:true,
      ydmodel: false,
      // st
      xzvideo: {
        img: require('../../assets/pc/st/sc/img/1.png'),
        video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st1.mp4',
      },
      stvideolist: [
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st1.mp4',
          path: require('../../assets/pc/st/sc/video/st1fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st2.mp4',
          path: require('../../assets/pc/st/sc/video/st2fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st3.mp4',
          path: require('../../assets/pc/st/sc/video/st3fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st4.mp4',
          path: require('../../assets/pc/st/sc/video/st4fm.jpg')
        },
      ],
      stimglist: [
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/1.png')
        },
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/2.png')
        }, {
          video: '',
          path: require('../../assets/pc/st/sc/img/3.png')
        }, {
          video: '',
          path: require('../../assets/pc/st/sc/img/4.png')
        },
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/5.jpg')
        }
      ],
      isimgbox: false,
      isvideobox: false,
      lblist: [
        {
          path: require('../../assets/mobile/news/lb/1.jpg'),
        },
        {
          path: require('../../assets/mobile/news/lb/2.jpg'),
        }
      ],
      lbtslist: [
        {
          path: require('../../assets/mobile/lbimg/01.jpg')
        },
        {
          path: require('../../assets/mobile/lbimg/02.jpg')
        },
        {
          path: require('../../assets/mobile/lbimg/03.jpg')
        },
        {
          path: require('../../assets/mobile/lbimg/04.jpg')
        },
        {
          path: require('../../assets/mobile/lbimg/05.jpg')
        }
      ],
      options: {
        afterLoad: this.afterLoad,
      },
      activeIndex: 0,
      activeIndexone: 0,
      menulist: [
        {
          name: '首页',
        },
        {
          name: '资讯'
        },
        {
          name: '职业介绍'
        },
        {
          name: '视听中心'
        },
        {
          name: '游戏特色'
        },
        {
          name: '社群'
        }
      ],
      ismenu: false,
      newsindex: 0,
      newslist: [
        {
          name: '最新'
        },
        {
          name: '新闻'
        },
        {
          name: '公告'
        },
        {
          name: '活动'
        },
      ],
      newscontent: [
        {
          name: '《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        }
      ],
      // 下载链接
      xzlink: '',
    }
  },
  computed: {
    propsmenuindex() {
      return this.$store.state.menuindex
    }
  },

  watch: {
    // 菜单切换
    propsmenuindex: function (val) {
      console.log('监听器', val)
      switch (val) {
        case 0:
          this.$refs.fullpage.api.moveTo(1, 0)
          break
        case 1:
          this.$refs.fullpage.api.moveTo(2, 1)
          break
        case 2:
          this.$refs.fullpage.api.moveTo(3, 2)
          break
        case 3:
          this.$refs.fullpage.api.moveTo(4, 3)
          break
        case 4:
          this.$refs.fullpage.api.moveTo(5, 4)
          break
        case 5:
          this.$refs.fullpage.api.moveTo(6, 5)
          break
      }
    }
  },
  mounted() {
    this.menuinit()
  },
  methods: {
    clickgotappath() {
      window.open('https://l.tapdb.net/3PVEzYfe?channel=rep-rep_ajzngmj2346', '_blank')
    },
    dowloadlink() {
      axios({
        method: 'post',
        url: 'https://sdk.nextjoy.com/channel/package/get_download_url',
        data: qs.stringify({
          channelPackageId: this.$route.query.subchannel,
          mercId: this.$route.query.merchID
        })
      }).then(res => {
        this.xzlink = res.data.data.downloadUrl
        const a = document.createElement("a");
        a.href = this.xzlink;
        a.click();
        window.URL.revokeObjectURL(this.xzlink);
        a.remove();
      })
    },
    ongamedownload() {
      const ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      // 在微信里面跳转引导页
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        // this.ydmodel = true
        this.$router.push({
          name: 'linkpage', query: {
            merchID: this.$route.query.merchID,
            subchannel: this.$route.query.subchannel,
          }
        })
      } else {
        // const ua = window.navigator.userAgent
        if (ua.indexOf('android') >= 0) {
          // window.open('https://a3page.nextjoy.com/', '_blank')
          // 安卓下载
          this.dowloadlink()
          // this.$router.push({
          //   name: 'linkpage', query: {
          //     merchID: this.$route.query.merchID,
          //     subchannel: this.$route.query.subchannel,
          //   }
          // })
        } else {
          // ios跳转
          // location.href = "https://apps.apple.com/cn/app/id1639842761"
          window.open('https://apps.apple.com/cn/app/id1573385372', '_blank')
        }
        this.ydmodel = false
      }
    },
    golinkfun(item) {
      if (item == 'dy') {
        window.open('https://v.douyin.com/ivEfQHB/', '_blank')
      } else if (item == 'wb') {
        window.open('https://weibo.com/u/7810973153', '_blank')
      } else if (item == 'bl') {
        window.open('https://space.bilibili.com/3493140740704897', '_blank')
      } else if (item == 'tap') {
        window.open('https://l.tapdb.net/3PVEzYfe?channel=rep-rep_ajzngmj2346', '_blank')
      } else if (item == 'wx') {
        // let path = require('../../assets/mobile/smwx.jpg')
        let path = require('../../assets/smwx.jpg')
        this.onboximg(path)
        // window.open('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzI4MDUyMDE1Nw==&scene=124#wechat_redirect', '_blank')
      } else if(item == 'mrzx'){
        let path = require('../../assets/mobile/xz/ewm.png')
        this.onboximg(path)
      } else if (item == 'qq') {
        window.open('http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=DBSy5qUMdwKKXCLPfAgGPPzl-tu5lS--&authKey=GUVdpByljOWQ2SLuDjc1%2BMOraPavth%2Bn%2Fb8RjL9GjBpls3YRiNP2btZroVuAj76i&noverify=0&group_code=131912270', '_blank')
      }
    },
    // 新闻跳转
    shownewstext(item) {
      this.$router.push({
        name: 'newscontext', query: {
          article_id: item.id,
          merchID: this.$route.query.merchID,
          subchannel: this.$route.query.subchannel,
        }
      })
      console.log(item)
    },
    // 新闻查看更多
    gonewsfun() {
      // this.$router.push('/newsindexmb')
      this.$router.push({
        name: 'newsindexmb', query: {
          merchID: this.$route.query.merchID,
          subchannel: this.$route.query.subchannel,
        }
      })
    },
    menuinit() {
      // 如果有参数跳转到指定路由
      if (this.$route.query.menuid) {
        console.log(this.$route.query.menuid)
        let index = Number(this.$route.query.menuid)
        setTimeout(() => {
          this.$store.commit('setmenuindex', index)
        }, 100);

      }
    },

    onplayvidow(item) {
      this.xzvideo.video = item
      this.isvideobox = true
      console.log(item, '打开视频')
    },
    onboximg(item) {
      this.xzvideo.img = item
      this.isimgbox = true
      console.log(item, '图片弹框')
    },
    shuffle(array) {
      const tempArray = array.map(value => ({ value, i: Math.random() }))
      tempArray.sort((a, b) => a.i - b.i)
      array.forEach((_, i) => {
        array[i] = tempArray[i].value
      })
      return array
    },
    oncheckfun() {
      let removedItem = this.stvideolist.shift();
      console.log(removedItem)
      this.stvideolist.push(removedItem)
      // this.stvideolist = this.shuffle(this.stvideolist)
      this.stimglist = this.shuffle(this.stimglist)
      console.log('切换图片视频')
    },
    setType(index) {
      let type;
      if (index == 1) {
        type = 50;
      } else if (index == 2) {
        type = 51;
      } else if (index == 3) {
        type = 52;
      }
      return type;
    },
    onnewsfun(item, index) {
      this.newsindex = index
      // this.istab = index
      if (index == '0') {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: 1,
            limit: '10',
            // type_id:'50'
          }
        }).then(res => {
          console.log('init', res)
          this.newscontent = res.data.data.time_news
        })
      } else {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: 1,
            limit: '10',
            type_id: this.setType(index)
          }
        }).then(res => {
          // 装载news.data 数据
          this.newscontent = res.data.data.news.data
        })
      }
    },
    getlist() {
      // 渲染新闻数据
      axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
        params: {
          pubkey: "3253942b24c7ad7eca566bf92696e1a6",
          page: 1,
          limit: '5',
          // type_id:'50'
        }
      }).then(res => {
        console.log('init', res)
        this.newscontent = res.data.data.time_news
        // this.$store.commit('setmewsmain', res.data.data.time_news)
      })
    },
    handleChange(index) {
      this.activeIndex = index;
      console.log('当前活动项的索引是：', index);
    },
    handleChangeone(index) {
      this.activeIndexone = index;
      console.log('当前活动项的索引是：', index);
    },
    switchToSecondItem(index) {
      this.$refs.swiper.swipeTo(index);
    },
    switchToSecondItemone(index) {
      this.$refs.swiperone.swipeTo(index);
    },

    // ts
    ontscontext(item) {
      if (item == 'left') {
        this.$refs.swiper.swipeTo(this.activeIndex - 1)
      } else {
        this.$refs.swiper.swipeTo(this.activeIndex + 1)
      }
    },
    // 轮播图
    afterLoad(origin, destination) {
      console.log(origin, destination)
      this.$store.commit('setmenuindex', destination.index)
      // origin 起点 destination终点 direction方向 固定写法
      // this.navIndex = destination.index;
      // this.tabIndex = 0
      // this.rigthImg = this.tabImgList[0].rightCont
    },
    // 菜单跳转
    onmenufun(item, index) {
      this.$store.commit('setmenuindex', index)
      // close
      this.ismenu = false
      // this.menuindex = index
      console.log(item, index)
    }
  }
}
</script>
<style scoped>
.mrzxbg{
  background: url(../../assets/mobile/xz/mrzxbg.png) no-repeat;
  background-size: 100% 100%;
}
/* 角色动画 */
.textanimation {
  animation-name: textdh;
  animation-duration: 2s;
}

@keyframes textdh {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.clearfix::before {
  content: '';
  display: table;
}

.bg_01 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_01.png) no-repeat;
  background-position: center center;
  background-size: cover
}

.bg_02 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_02.png) no-repeat;
  background-size: 100% 100%;
}

.bg_03 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_03.png) no-repeat;
  background-size: 100% 100%;
}

.bg_04 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_04.png) no-repeat;
  background-size: 100% 100%;
}

.bg_05 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_05.png) no-repeat;
  background-size: 100% 100%;
}

.bg_06 {
  width: 100%;
  height: 100%;
  background: url(../../assets/mobile/bg_06.jpg) no-repeat;
  background-size: 100% 100%;
}

.menutext {
  /* width: 42px;
  height: 21px; */
  font-size: 1.3125rem;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #FFFFFF;
  /* line-height: 27px; */
  /* opacity: 0.5; */
}

.menubg {
  background: url(../../assets/mobile/leftmenu/menubg.png) no-repeat;
  background-size: 100% 100%;
}

.ztitletext {

  /* font-size: .8125rem;
  font-family: Source Han Serif SC;
  font-weight: 400;
  color: rgba(246, 217, 173, 0.77);

  text-shadow: 0px 0px .1875rem #000000; */
  font-size: .6875rem;
  font-family: IdeaFonts;
  font-weight: normal;
  color: #FFFFFF;

  background: linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 60.44921875%, #ACACAC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ztitlebg {
  background: url(../../assets/mobile/titlebg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
