<template>
  <div>
    <div class="z-[1045] fixed w-[100vw] h-[100vh] bg-white" v-if="ydmodel">
      <img src="../../assets/mtydpage.jpg" alt="">
    </div>
    <div class="headerbg fixed w-full h-[3.5625rem] flex items-center z-[1000] justify-between">
      <div class="flex">
        <img src="../../assets/mobile/logo.png" class="w-[2.375rem] h-[2.375rem] ml-[.625rem]" alt="">
        <div class="flex flex-col ml-[.9375rem]">
          <span class="title">战之刃：幸存者</span>
          <span class="ztitle">暗黑魔幻生存MMORPG</span>
        </div>
      </div>
      <div class="w-[8.6875rem] h-[2.875rem] downloadbg flex justify-center " @click="ongamedownload()">
        <span class="dowtext mt-[.8rem]" v-if="true">游戏下载</span>
        <!-- <span class="dowtext mt-[.8rem]">立即预约</span> -->
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios';
export default {
  name: 'menupagemb',
  data() {
    return {
      // 下载链接
      xzlink: '',
      ydmodel: false,
    }
  },
  created() {
  },
  components: {

  },
  methods: {
    dowloadlink() {
      axios({
        method: 'post',
        url: 'https://sdk.nextjoy.com/channel/package/get_download_url',
        data: qs.stringify({
          channelPackageId: this.$route.query.subchannel,
          mercId: this.$route.query.merchID
        })
      }).then(res => {
        this.xzlink = res.data.data.downloadUrl
        const a = document.createElement("a");
        a.href = this.xzlink;
        a.click();
        window.URL.revokeObjectURL(this.xzlink);
        a.remove();
      })
    },
    ongamedownload() {
      const ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      // 在微信里面跳转引导页
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.ydmodel = true
      } else {
        if (ua.indexOf('android') >= 0) {
          // 安卓下载
          // window.open('https://a3page.nextjoy.com/', '_blank')
          this.dowloadlink()
        } else {
          // ios跳转
          // location.href = "https://apps.apple.com/cn/app/id1639842761"
          window.open('https://apps.apple.com/cn/app/id1573385372', '_blank')
        }
       this.ydmodel = false
      }

    }
  }
}
</script>

<style scoped>
.dowtext {
  /* width: 59px;
  height: 13px; */
  font-size: .875rem;
  font-family: Source Han Serif SC;
  font-weight: 600;
  color: #FFFFFF;
  /* line-height: 16px; */
}

.downloadbg {
  background: url(../../assets/mobile/headdownloadbg.png) no-repeat;
  background-size: 100% 100%;
}

.ztitle {
  /* width: 69px;
  height: 12px; */
  font-size: .6875rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.55);
  /* line-height: 16px; */
}

.title {
  /* width: 65px;
  height: 13px; */
  font-size: .8125rem;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #FFFFFF;
  /* line-height: 15px; */
}

.headerbg {
  background: url(../../assets/mobile/header.png) no-repeat;
  background-size: 100% 100%;
}
</style>
