var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isfooter)?_c('div',{staticClass:"flex justify-center w-full",style:({ backgroundColor: _vm.colorvalue })},[_c('div',{staticClass:"my-2 w-full"},[_vm._m(0),_c('div',{staticClass:"text text-center cursor-pointer"},[_c('span',{on:{"click":function($event){return _vm.footerxx(1)}}},[_vm._v("隐私协议")]),_vm._v("  |  "),_c('span',{on:{"click":function($event){return _vm.footerxx(2)}}},[_vm._v("用户协议")])]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"text text-center"},[_c('span',{on:{"click":function($event){return _vm.goicp()}}},[_vm._v("沪ICP备17036270号-1")])]),_vm._m(3),_vm._m(4),_vm._m(5)])]):_vm._e(),(_vm.isfooterbj)?_c('div',{staticClass:"flex justify-center w-full",style:({ backgroundColor: _vm.colorvalue })},[_c('div',{staticClass:"my-2 w-full"},[_vm._m(6),_c('div',{staticClass:"text text-center cursor-pointer"},[_c('span',{on:{"click":function($event){return _vm.footerxx(1)}}},[_vm._v("隐私协议")]),_vm._v("  |  "),_c('span',{on:{"click":function($event){return _vm.footerxx(2)}}},[_vm._v("用户协议")])]),_c('div',{staticClass:"text text-center"},[_c('span',{on:{"click":function($event){return _vm.goicp()}}},[_vm._v("京ICP备2022024502号-3")])]),_vm._m(7)])]):_vm._e(),(_vm.isfooterTy)?_c('div',{staticClass:"flex justify-center w-full",style:({ backgroundColor: _vm.colorvalue })},[_c('div',{staticClass:"my-2 w-full"},[_vm._m(8),_c('div',{staticClass:"text text-center cursor-pointer"},[_c('span',{on:{"click":function($event){return _vm.footerxx(1)}}},[_vm._v("隐私协议")]),_vm._v("  |  "),_c('span',{on:{"click":function($event){return _vm.footerxx(2)}}},[_vm._v("用户协议")])]),_c('div',{staticClass:"text text-center"},[_c('span',{on:{"click":function($event){return _vm.goicp()}}},[_vm._v("沪ICP备16045192号-1")])]),_vm._m(9)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center gap-1 items-center"},[_c('div',[_c('img',{staticClass:"w-[6.875rem] h-[1.75rem]",attrs:{"src":require("./img/foot2.png"),"alt":"明日世界logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex justify-center items-center pb-1"},[_c('img',{staticClass:"w-[.7] h-[.7rem]",attrs:{"src":require("./img/footga.png"),"alt":"网文logo"}}),_c('span',[_vm._v("网络文化经营许可证：沪网文 (2020）3528-249号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text flex justify-center items-center"},[_c('img',{staticClass:"w-[0.6rem] h-[.6rem]",attrs:{"src":require("./img/beian.png"),"alt":"公安logo"}}),_vm._v("公安备案号：31010502004459号 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text-center"},[_c('span',[_vm._v("明日世界（上海）网络科技有限公司")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text-center"},[_c('span',[_vm._v("增值电信业务经营许可证：沪B2-20170436")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text-center"},[_c('span',[_vm._v("ⓒ Netmarble Corp. & Netmarble N2 Inc. All Rights Reserved.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center gap-1 items-center"},[_c('div',[_c('img',{staticClass:"w-[6.875rem] h-[1.75rem]",attrs:{"src":require("./img/foot2.png"),"alt":"明日世界logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text-center"},[_c('span',[_vm._v("明日世界（北京）科技有限公司 京B2-20224967")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center gap-1 items-center"},[_c('div',[_c('img',{staticClass:"w-[6.875rem] h-[1.75rem]",attrs:{"src":require("./img/foot2.png"),"alt":"明日世界logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text text-center"},[_c('span',[_vm._v("明日世界竞技体育发展有限公司")])])
}]

export { render, staticRenderFns }