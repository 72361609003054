import { render, staticRenderFns } from "./menupage.vue?vue&type=template&id=6d046246&scoped=true&"
import script from "./menupage.vue?vue&type=script&lang=js&"
export * from "./menupage.vue?vue&type=script&lang=js&"
import style0 from "./menupage.vue?vue&type=style&index=0&id=6d046246&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d046246",
  null
  
)

export default component.exports