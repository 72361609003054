<template>
  <div class="w-full h-[100vh]" v-if="idbol">
    <img src="../../assets/ydbg.jpg" class="w-full h-full object-cover" alt="">
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs'
export default {
  name: 'linkPage',
  data() {
    return {
      idbol: false,
      // 下载链接
      xzlink: '',
    }
  },
  mounted() {
    this.getinit()
  },
  created() {
    // this.dowloadlink()
  },
  methods: {
    dowloadlink() {
      axios({
        method: 'post',
        url: 'https://sdk.nextjoy.com/channel/package/get_download_url',
        data: qs.stringify({
          channelPackageId: this.$route.query.subchannel,
          mercId: this.$route.query.merchID
        })
      }).then(res => {
        this.xzlink = res.data.data.downloadUrl
        this.down(this.xzlink)
      })
    },
    down(path) {
      let objectUrl = path
      const a = document.createElement("a")
      a.href = objectUrl
      a.click()
      window.URL.revokeObjectURL(objectUrl);
      a.remove('')
    },
    getinit() {
      console.log('进入下载引导页')
      const ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      var isMobile = /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);
      if (isMobile) {
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          this.idbol = true
        } else {
          if (ua.indexOf('android') >= 0) {
            this.dowloadlink()
            this.$router.push({
              name: 'indexpage', query: {
                merchID: this.$route.query.merchID,
                subchannel: this.$route.query.subchannel,
              }
            })
            // 预约
            // window.open('https://a3page.nextjoy.com/', '_blank')
            // 下载
         
          } else {
            this.idbol = false
            this.$router.push({
              name: 'indexpage', query: {
                merchID: this.$route.query.merchID,
                subchannel: this.$route.query.subchannel,
              }
            })
            location.href = "https://apps.apple.com/cn/app/id1573385372"
          }
        }
      } else {
        console.log("这是PC端跳回首页");
        this.$router.push({
          name: 'indexpage', query: {
            merchID: this.$route.query.merchID,
            subchannel: this.$route.query.subchannel,
          }
        })
      }

    }
  },
  components: {

  }
}
</script>

<style scoped></style>
