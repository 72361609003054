<!-- <script setup>
import { onMounted, ref } from 'vue';
onMounted(() => {
  geturlpage()
})

function footerxx(item) {
  if (item == '1') {
    window.open('https://sdk.nextjoy.com/init/init/getPrivacy?sdkver=3.0.0', '_blank');
  } else if (item == '2') {
    window.open('https://sdk.nextjoy.com/init/init/getRegster', '_blank');
  }
}
function goicp() {
  window.open('https://beian.miit.gov.cn', '_blank');
}

const isfooter = ref(true)
const isfooterTy = ref(false)



defineProps(['colorvalue'])
</script> -->

<template>
  <div>
    <!-- 公司信息栏 -->
    <div class="flex justify-center w-full " :style="{ backgroundColor: colorvalue }" v-if="isfooter">
      <div class="my-2 w-full">
        <div class="flex justify-center gap-1 items-center">
          <div> <img src="./img/foot2.png" alt="明日世界logo" class="w-[6.875rem] h-[1.75rem]"></div>
        </div>
        <div class="text text-center cursor-pointer "> <span
            @click="footerxx(1)">隐私协议</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="footerxx(2)">用户协议</span>
        </div>
        <div class="text flex justify-center items-center  pb-1">
          <img src="./img/footga.png" class="w-[.7] h-[.7rem]" alt="网文logo">
          <span>网络文化经营许可证：沪网文 (2020）3528-249号</span>
        </div>
        <div class="text flex justify-center items-center ">
          <img src="./img/beian.png" class="w-[0.6rem] h-[.6rem]" alt="公安logo">公安备案号：31010502004459号
        </div>
        <div class="text text-center "> <span @click="goicp()">沪ICP备17036270号-1</span></div>
        <div class="text text-center "><span>明日世界（上海）网络科技有限公司</span></div>
        <div class="text text-center "> <span>增值电信业务经营许可证：沪B2-20170436</span></div>
        <div class="text text-center "> <span>ⓒ Netmarble Corp. & Netmarble N2 Inc. All Rights Reserved.</span></div>
      </div>
    </div>
    <!-- 公司信息栏 -->
    <div class="flex justify-center w-full " :style="{ backgroundColor: colorvalue }" v-if="isfooterbj">
      <div class="my-2 w-full">
        <div class="flex justify-center gap-1 items-center">
          <div> <img src="./img/foot2.png" alt="明日世界logo" class="w-[6.875rem] h-[1.75rem]"></div>
        </div>
        <div class="text text-center cursor-pointer "> <span
            @click="footerxx(1)">隐私协议</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="footerxx(2)">用户协议</span>
        </div>
        <!-- <div class="text flex justify-center items-center  pb-1">
          <img src="./img/footga.png" class="w-[.7] h-[.7rem]" alt="网文logo">
          <span>网络文化经营许可证：沪网文 (2020）3528-249号</span>
        </div> -->
        <!-- <div class="text flex justify-center items-center ">
          <img src="./img/beian.png" class="w-[0.6rem] h-[.6rem]" alt="公安logo">公安备案号：31010502004459号
        </div> -->
        <div class="text text-center "> <span @click="goicp()">京ICP备2022024502号-3</span></div>
        <div class="text text-center "><span>明日世界（北京）科技有限公司 京B2-20224967</span></div>
        <!-- <div class="text text-center "> <span>增值电信业务经营许可证：沪B2-20170436</span></div> -->
      </div>
    </div>
    <!-- 体育底部信息 -->
    <div class="flex justify-center w-full " :style="{ backgroundColor: colorvalue }" v-if="isfooterTy">
      <div class="my-2 w-full">
        <div class="flex justify-center gap-1 items-center">
          <div> <img src="./img/foot2.png" alt="明日世界logo" class="w-[6.875rem] h-[1.75rem]"></div>
        </div>
        <div class="text text-center cursor-pointer "> <span
            @click="footerxx(1)">隐私协议</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="footerxx(2)">用户协议</span>
        </div>
        <!-- <div class="text flex justify-center items-center  pb-1">
          <img src="./img/footga.png" class="w-[.7] h-[.7rem]" alt="网文logo">
          <span>网络文化经营许可证：沪网文 (2020）3528-249号</span>
        </div> -->
        <!-- <div class="text flex justify-center items-center ">
          <img src="./img/beian.png" class="w-[0.6rem] h-[.6rem]" alt="公安logo">公安备案号：31010502004459号
        </div> -->
        <div class="text text-center "> <span @click="goicp()">沪ICP备16045192号-1</span></div>
        <div class="text text-center "><span>明日世界竞技体育发展有限公司</span></div>
        <!-- <div class="text text-center "> <span>增值电信业务经营许可证：沪B2-20170436</span></div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mobilefooter",
  data() {
    return {
      isfooter: true,
      isfooterbj: false,
      isfooterTy: false
    }
  },
  props: ['colorvalue'],
  created() {
    this.geturlpage()
  },
  methods: {
    footerxx(item) {
      if (item == '1') {
        window.open('https://sdk.nextjoy.com/init/init/getPrivacy?sdkver=3.0.0', '_blank');
      } else if (item == '2') {
        window.open('https://sdk.nextjoy.com/init/init/getRegster', '_blank');
      }
    },
    geturlpage() {
      console.log('进入判断')
      if (window.location.host == 'a3.bjnextworld.com' || window.location.host == 'wjmt.nextworld.net.cn' || window.location.host == 'wjmt.bjnextworld.com' || window.location.host == 'bjnextworld.com' || window.location.host == 'nextworld.net.cn' || window.location.host == 'www.nextworld.net.cn') {
        this.isfooterTy = false
        this.isfooter = false
        this.isfooterbj = true
      } else if (window.location.host == 'wjmt.nextjoy.com.cn') {
        this.isfooterTy = true
        this.isfooter = false
        this.isfooterbj = false
      } else if (window.location.host == 'xczgame.nextjoy.com' || window.location.host == 'wjmt.nextjoy.com') {
        this.isfooterTy = true
        this.isfooter = false
        this.isfooterbj = false
      } else {
        this.isfooterTy = false
        this.isfooterbj = false
        this.isfooter = true
      }
      console.log(' window.location.host', window.location.host)
    }
  }
}
</script>
<style scoped>
.text {
  font-size: 0.5rem;
  color: rgb(140, 140, 140)
}
</style>