<template>
  <div>
    <menupage></menupage>
    <leftmenu></leftmenu>
    <rightfixed></rightfixed>
    <!-- 明日之星下载 -->
    <div class="fixed top-[7.5rem] right-[3.9375rem] z-[1000]" v-if="false">
      <div class="mrzxbg w-[16.1875rem] h-[7.625rem] relative clearfix">
        <!-- logo -->
        <div class=" absolute top-[-1rem] left-[.75rem]">
          <img src="../../assets/pc/xz/masterbut/mrzxlogo.png" class="w-[2.4375rem] h-[2.8125rem]" alt="">
        </div>
        <!-- 关闭 -->
        <div class="absolute top-[-1.0625rem] right-[.5rem] cursor-pointer" @click="ismrzx = false">
          <img src="../../assets/pc/xz/masterbut/mrzxclose.png" class="w-[2.8125rem] h-[2.8125rem]" alt="">
        </div>
        <!-- 标题 -->
        <div class="flex justify-center mt-[.15rem]">
          <span class="text-[1.25rem] text-[#ffffff]">明日之星App</span>
        </div>
        <!-- 内容 -->
        <div class="mt-2 flex justify-between">
          <div class="flex flex-col ml-[.8625rem]">
            <span class="text-[.8375rem] text-[#ffffff] ">领取更多游戏福利</span>
            <span class="text-[.8375rem] text-[#ffffff] mt-1">获取最新 ~</span>
            <span class="text-[.8375rem] text-[#ffffff] ">游戏资讯&攻略</span>
          </div>
          <div class="flex mr-2">
            <div class=" mt-[.8125rem]">
              <span class="cursor-vertical-text textsm text-[.8875rem] text-[#ffffff]">扫码下载</span>
            </div>
            <div class="mt-[.2125rem]">
              <img src="../../assets/pc/xz/masterbut/ewm.png" class="w-[4.625rem] h-[4.625rem]" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex fixed bottom-[3rem] right-[1.375rem] cursor-pointer z-[1000]" v-if="!ismrzx" @click="ismrzx = true">
      <img src="../../assets/pc/xz/masterbut/logodt.gif" class="w-[5.6875rem] h-[5.4375rem]" alt="">
    </div>
    <div class="fixed z-[1000] bottom-[3rem] right-[1.375rem] flex items-center" v-if="ismrzx">
      <img src="../../assets/pc/xz/masterbut//mrzx/back.png" @click="ismrzx = false" class=" cursor-pointer w-[2.4375rem] h-[2.4375rem]" alt="">
      <div class="w-[24rem] h-[8.875rem] bgmrzx ml-[.6875rem] flex items-center justify-start">
        <div class="w-[5.125rem] h-[6.125rem] bgewm ml-[1.375rem] flex flex-col items-center justify-center">
          <img src="../../assets/pc/xz/masterbut/ewm.png" class="w-[5.125rem] h-[5.125rem]" alt="">
          <span class="text-[.875rem] text-[#000000]">扫码下载</span>
        </div>
        <img src="../../assets/pc/xz/masterbut/mrzx/logo.png" class="w-[4.5625rem] h-[4.6875rem] ml-[.375rem]" alt="">
        <div class="w-[.1875rem] h-[4.1875rem] bg-[#FFFFC0] border-[.0625rem]  border-[#05072A] ml-[.6875rem]"></div>
        <div class="flex flex-col justify-center ml-[.875rem]">
          <span class="mrzxtitle">明日之星App</span>
          <span class="mrzxtext">领取更多游戏福利!</span>
          <span class="mrzxtext">获取最新游戏资讯&攻略</span>
        </div>
      </div>
    </div>

    <!-- 视频弹框 -->
    <div class="w-[100vw] h-[100vh] z-[10001] fixed bg-black" v-if="isvideo">
      <div class="flex justify-center items-start h-full w-full">
        <video controls preload="auto" ref="modelvideo" autoplay loop playsinline muted="muted"
          class="w-[80vw] h-[80vh] mt-[5%]" :src="showvideo"></video>
        <div class="text-[#ffffff] mt-[5%] ml-[1%] cursor-pointer" @click="isvideo = false">
          <img src="../../assets/pc/role/close.png" class="w-[1.5625rem] h-[1.5625rem]" alt="">
        </div>
      </div>
    </div>
    <!-- 图片弹框 -->
    <div class="w-[100vw] h-[100vh] z-[10001] fixed bg-black" v-if="isimgpath">
      <div class="flex justify-center items-start h-full w-full">

        <div class="w-[80vw] h-[80vh] mt-[5%] flex justify-center">
          <img :src="showimgpath" class="object-contain w-full h-full" alt="">
        </div>
        <div class="text-[#ffffff]  cursor-pointer mt-[5%] ml-[1%] " @click="isimgpath = false">
          <img src="../../assets/pc/role/close.png" class="w-[1.5625rem] h-[1.5625rem]" alt="">
        </div>
      </div>
    </div>
    <full-page :options="options" ref="fullpage">
      <div class=" w-full h-[100vh] relative section">
        <div class="video-box w-[100%] h-[100vh] z-[1]">
          <video class="video-background" preload="auto" ref="videoid" loop playsinline autoplay
            src="https://a3.game.nextjoy.com/a3_web/xczvideo/new/headerbg.mp4" tabindex="-1" muted="muted">
          </video>
          <div class="absolute bottom-[20.75rem] flex justify-center w-full">
            <img src="../../assets/pc/slogan.png" class="w-[36.875rem] h-[6.75rem]" alt="">
          </div>
          <div class="absolute bottom-[18.5rem] flex w-full justify-center  ml-[-0.375rem]">
            <div class="smallslogan bg-no-repeat w-[36.8125rem] h-[2.3125rem] flex justify-center items-center ">
              <span class="slogantext">暗黑魔幻生存MMORPG手游</span>
            </div>
          </div>
          <!-- 下载 -->
          <div class="flex justify-center items-center w-full absolute bottom-[8.9375rem]">
            <!-- <img src="../../assets/pc/xz/masterbut/ios.png" alt="" class="w-[10rem] h-[3.4375rem] cursor-pointer"
              @click="goappfun('apple')">
            <img src="../../assets/pc/xz/masterbut/az.png" alt=""
              class="ml-[1.1875rem] w-[10rem] h-[3.4375rem] cursor-pointer" @click="goappfun('az')">
            <img src="../../assets/pc/xz/masterbut/tap.png" alt=""
              class="ml-[1.1875rem] w-[10rem] h-[3.4375rem] cursor-pointer" @click="goappfun('tap')">
            <img src="../../assets/pc/xz/masterbut/ld.png" alt=""
              class="ml-[1.1875rem] w-[10rem] h-[3.4375rem] cursor-pointer" @click="goappfun('ld')">
            <img src="../../assets/pc/xz/masterbut/mumu.png" alt=""
              class="ml-[1.1875rem] w-[10rem] h-[3.4375rem] cursor-pointer " @click="goappfun('mumu')"> -->
            <div class="downloadbox w-[18.75rem] h-[7.375rem] flex items-center" v-if="true">
              <div class="ewmimg w-[7.5rem] h-[6.125rem] flex items-center justify-start ml-[1rem]">
                <span class="textsm text-[#ffffff] text-[.8125rem]">扫码下载游戏</span>
              </div>
              <!-- <img src="../../assets/download.png" class="w-[5.75rem] h-[5.75rem]" alt=""> -->
              <!-- <img src="../../assets/test_xz.png" class="w-[5.75rem] h-[5.75rem]" alt=""> -->
              <div class="flex flex-col ml-[.5rem] mt-[.2125rem]">
                <img src="../../assets/pc/xz/masterbut/ios.png" alt="" class="w-[8.1875rem] h-[2.8125rem] cursor-pointer"
                  @click="goappfun('apple')">
                <img src="../../assets/pc/xz/masterbut/az.png" alt=""
                  class="w-[8.1875rem] h-[2.75rem] mt-[.5rem] cursor-pointer" @click="goappfun('az')">
              </div>
              <!-- <div class="flex flex-col ml-[.5rem] mt-[.2125rem]">
                <img src="../../assets/pc/xz/masterbut/tap.png" alt="" class="w-[8.1875rem] h-[2.8125rem] cursor-pointer"
                  @click="goappfun('tap')">
                <img src="../../assets/pc/xz/masterbut/mumu.png" alt=""
                  class="w-[8.1875rem] h-[2.8125rem] mt-[.6875rem] cursor-pointer" @click="goappfun('mumu')">
              </div>
              <div class="flex flex-col ml-[.4rem] h-full mt-[1.7125rem]">
                <img src="../../assets/pc/xz/masterbut/ld.png" alt="" class="w-[6.25rem] h-[5.9375rem] cursor-pointer "
                  @click="goappfun('ld')">
              </div> -->
              <!-- <img src="../../assets/pc/a3.png" class="w-[5.75rem] h-[5.75rem] ml-[.9375rem]" alt=""> -->
            </div>
          </div>
          <!-- 下载二维码 -->
          <div class="flex flex-col absolute bottom-[3.5625rem] right-4" v-if="false">
            <span class="text-[#ffffff] text-[1.125rem] font-[500] text-center">扫码下载</span>
            <img src="../../assets/download.png" class="w-[5.75rem] h-[5.75rem]" alt="">
            <!-- <img src="../../assets/test_xz.png" class="w-[5.75rem] h-[5.75rem]" alt=""> -->
          </div>
          <!-- 滚动提示 -->
          <div class="flex justify-center w-full absolute bottom-[3.5625rem] gamelogo" v-if="true">
            <img src="../../assets/pc/scroll.png" class="w-[4.75rem] h-[4.0625rem]" alt="">
          </div>
        </div>

      </div>
      <div class="bg_02 section relative">
        <div class="absolute top-[7rem] left-[50%] translate-x-[-50%] w-[6.9375rem] h-[3.375rem] z-[1]"><img
            src="../../assets/pc/newsimg/titletext.png" alt=""></div>
        <newspage></newspage>
      </div>
      <!-- 角色 -->
      <div class="bg_03 section h-[100vh]">
        <div class="w-full h-full relative">
          <!-- 页面标题 -->
          <div class="absolute top-[13.125rem] left-[22.8125rem]">
            <img src="../../assets/pc/role/title.png" class="w-[14.4375rem] h-[3.3125rem]" alt="">
          </div>
          <!-- 职业logo -->
          <div class="absolute top-[20.375rem] left-[22.75rem]">
            <img :src="rolelist[0].icon" class="w-[4.625rem] h-[4.5rem]" alt="">
          </div>
          <!-- 职业名称 -->
          <div class="absolute top-[21.475rem] left-[28.875rem]">
            <span class="text-[#F7EDDD] text-[1.5rem]">{{ rolelist[0].name }}</span>
          </div>
          <!-- 介绍 -->
          <div class="absolute left-[22.875rem] top-[25.9375rem] w-[34.4375rem] overflow-auto z-[1]">
            <span class="text-[#FFFFFF] text-[1.125rem] rolemessage ">{{ rolelist[0].content }}</span>
          </div>
          <!-- 视频 -->
          <div class="absolute top-[31rem] left-[23rem] z-[1]">
            <div class="w-[29.55rem] h-[15rem] bg-black flex justify-center items-center relative"
              @click="onplayvideo(rolelist[0].videopath)">
              <img :src="rolelist[0].videofm" alt="" class="absolute w-full h-full z-[1] ">
              <img src="../../assets/pc/role/play.png" class="w-[3.75rem] h-[3.75rem] cursor-pointer z-[2]" alt="">
            </div>
          </div>
          <!-- 角色立绘 -->
          <div class="top-[2.4375rem] left-[31.875rem] absolute w-[70.9375rem] h-[63.375rem]">
            <img :src="rolelist[0].role" class="  object-cover" alt="">
          </div>
          <!-- 角色头像 -->
          <div
            class="absolute left-[95.3125rem] w-[7.6875rem] h-[7.5rem] rounded-[50%] top-[19.0625rem] z-[3] bg-[rgba(0,0,0,0.6)] cursor-pointer"
            :class="{ textanimation: disabled }" @click="onleft()"></div>
          <div class="absolute left-[95.3125rem] top-[19.0625rem] z-[2] " :class="{ textanimation: disabled }">
            <img :src="rolelist[5].imgpage" class="-[7.6875rem] h-[7.5rem] " alt="">
          </div>
          <div class="absolute left-[98.6875rem] top-[29.875rem] z-[2]" :class="{ textanimation: disabled }">
            <img :src="rolelist[0].imgpage" class="-[7.6875rem] h-[7.5rem]" alt="">
          </div>
          <div
            class="absolute left-[95.4375rem] w-[7.6875rem] h-[7.5rem] top-[40.5rem] z-[3] rounded-[50%] bg-[rgba(0,0,0,0.6)] cursor-pointer"
            :class="{ textanimation: disabled }" @click="onright()"></div>
          <div class="absolute left-[95.4375rem] top-[40.5rem] z-[2]" :class="{ textanimation: disabled }">
            <img :src="rolelist[1].imgpage" class="w-[7.6875rem] h-[7.5rem]" alt="">
          </div>
          <!--  -->
          <div
            class="w-[15.375rem] h-[40rem] relative top-[14.0625rem] left-[87.5625rem] z-[1] overflow-hidden flex items-center">
            <div class=" h-[34.25rem] w-[34.25rem] rounded-[50%] absolute right-0 bg-[rgba(0,0,0,0.6)] z-[1] "
              v-if="false"></div>
            <div class="absolute right-0 h-[34.25rem] w-[34.25rem]" id="circle">
              <img src="../../assets/pc/role/round.png" class="" alt="">
            </div>
            <!-- <div class="rounded-[50%] absolute right-0 h-[40rem] w-[40rem] bg-yellow-800" id="circle">
            </div> -->

          </div>
          <!-- 切换器 -->
          <div class="top-[29.5rem] left-[17.25rem] absolute cursor-pointer">
            <img src="../../assets/pc/role/left.png" class="w-[4.8125rem] h-[9.0625rem]" alt="" @click="onleft()">
          </div>
          <div class="top-[29.5rem] right-[10.25rem] absolute cursor-pointer">
            <img src="../../assets/pc/role/right.png" class="w-[4.8125rem] h-[9.0625rem]" alt="" @click="onright()">
          </div>
        </div>
      </div>
      <!-- 视听 -->
      <div class="bg_04 section h-[100vh] relative">
        <div class="absolute top-[12.875rem] left-[53.125rem]">
          <img src="../../assets/pc/st/title.png" class="w-[14.5625rem] h-[3.375rem]" alt="">
        </div>
        <!-- 视频 -->
        <div
          class="absolute top-[18.4375rem] left-[17.8125rem] w-[45.8125rem] h-[22.1875rem] bg-black flex justify-center items-center">
          <img :src="stshowdata[0].path" class="absolute w-full h-full z-[1] object-cover" alt="">
          <img src="../../assets/pc/role/play.png" class="w-[3.5625rem] h-[3.6875rem] cursor-pointer z-[2]" alt=""
            @click="onplayvideo(stshowdata[0].video)">
        </div>
        <!-- 视频 -->
        <div
          class="absolute top-[18.4375rem] left-[63.9375rem] w-[19.8125rem] h-[12.1875rem] bg-black  flex justify-center items-center"
          @click="onplayvideo(stshowdata[1].video)">
          <img :src="stshowdata[1].path" class="absolute w-full h-full z-[1] object-cover" alt="">
          <img src="../../assets/pc/role/play.png" class="w-[3.5625rem] h-[3.6875rem] cursor-pointer z-[2]" alt="">
        </div>
        <!-- tu -->
        <div class="absolute top-[18.4375rem] left-[84rem] w-[19.8125rem] h-[12.1875rem] bg-black cursor-pointer "
          @click="onplayimg(stshowdata[4].path)">
          <img :src="stshowdata[4].path" class="absolute w-full h-full z-[1] object-cover " alt="">
        </div>
        <!-- tu -->
        <div class="absolute top-[30.9375rem] left-[63.9375rem] w-[19.8125rem] h-[12.1875rem] bg-black cursor-pointer"
          @click="onplayimg(stshowdata[5].path)">
          <img :src="stshowdata[5].path" class="absolute w-full h-full z-[1] object-cover object-top" alt="">
        </div>
        <!-- tu -->
        <div class="absolute top-[30.9375rem] left-[84rem] w-[19.8125rem] h-[12.1875rem] bg-black cursor-pointer"
          @click="onplayimg(stshowdata[6].path)">
          <img :src="stshowdata[6].path" class="absolute w-full h-full z-[1] object-cover " alt="">
        </div>
        <!-- 视频 -->
        <div
          class="absolute top-[41rem] left-[17.8125rem] w-[22.75rem] h-[14.75rem] bg-black flex justify-center items-center"
          @click="onplayvideo(stshowdata[2].video)">
          <img :src="stshowdata[2].path" class="absolute w-full h-full z-[1] object-cover" alt="">
          <img src="../../assets/pc/role/play.png" class="w-[3.5625rem] h-[3.6875rem] cursor-pointer z-[2]" alt="">
        </div>
        <!-- tu -->
        <div class="absolute top-[41rem] left-[40.875rem] w-[22.75rem] h-[14.75rem] bg-black cursor-pointer"
          @click="onplayimg(stshowdata[7].path)">
          <img :src="stshowdata[7].path" class="absolute w-full h-full z-[1] object-cover" alt="">
        </div>
        <!-- tu -->
        <div class="absolute top-[43.5rem] left-[63.9375rem] w-[19.8125rem] h-[12.25rem] bg-black cursor-pointer"
          @click="onplayimg(stshowdata[8].path)">
          <img :src="stshowdata[8].path" class="absolute w-full h-full z-[1] object-cover" alt="">
        </div>
        <!-- 视频 -->
        <div
          class="absolute top-[43.5rem] left-[84rem] w-[19.8125rem] h-[12.25rem] bg-black flex justify-center items-center"
          @click="onplayvideo(stshowdata[3].video)">
          <img :src="stshowdata[3].path" class="absolute w-full h-full z-[1] object-cover" alt="">
          <img src="../../assets/pc/role/play.png" class="w-[3.5625rem] h-[3.6875rem] cursor-pointer z-[2]" alt="">
        </div>
      </div>
      <div class="bg_05 section h-[100vh] relative ">
        <div class="absolute top-[12.9375rem] left-[52.6875rem]">
          <span class="tstitle flex justify-center items-center"><img src="../../assets/pc/yxts.png" alt=""></span>
        </div>
        <!-- 显示主体 -->
        <div class=" bg-black  w-[54.8125rem] h-[30.8125rem] absolute top-[18.4375rem] left-[32.875rem] shadow-2xl">
          <div class="shadow-2xl">
            <img :src="tslist[tsindex].name" alt="" class="shadow-2xl">
          </div>
        </div>
        <!-- 切换 -->
        <div class="bottom-[3.2rem] left-[43.75rem] absolute ">
          <div class="w-full h-full flex items-center cursor-pointer gap-[.625rem] ">
            <div class="w-[6rem] h-[3.8125rem] " :class="index == tsindex ? 'w-[7rem] h-[4.625rem]' : ''"
              v-for="(item, index) in tslist" @mouseenter="tsindex = index">
              <img :src="item.name" alt="" class="">
            </div>
          </div>
        </div>
        <!-- 按钮 -->
        <div class="absolute bottom-[3.2rem] left-[38.875rem] cursor-pointer" @click="ontsleft()">
          <img src="../../assets/pc/st/leftbut.png" class="w-[2.4375rem] h-[4.625rem]" alt="">
        </div>
        <div class="absolute bottom-[3.2rem] left-[79.125rem] cursor-pointer" @click="ontsright()">
          <img src="../../assets/pc/st/rightbut.png" class="w-[2.4375rem] h-[4.625rem]" alt="">
        </div>
      </div>
      <div class="section h-[100vh] relative bg-black">
        <div class="bg_06 w-full h-[100vh] clearfix relative">
          <div class="absolute top-[39.875rem] left-[21.5rem]" v-if="mrzxbol">
            <div class="bgmodel flex justify-center items-center">
              <img src="../../assets/pc/xz/masterbut/ewm.png" class="w-[9.175rem]" alt="">
            </div>
          </div>
          <div class="absolute top-[39.875rem] left-[32.5rem]" v-if="dybol">
            <div class="bgmodel flex justify-center items-center">
              <img src="../../assets/dyewm.png" class="w-[9.175rem]" alt="">
            </div>
          </div>
          <div class="absolute top-[39.875rem] left-[55rem]" v-if="wxbol">
            <div class="bgmodel flex justify-center items-center">
              <img src="../../assets/wxewm.png" class="w-[9.175rem]" alt="">
            </div>
          </div>
          <div class="absolute top-[39.875rem] left-[66rem]" v-if="qqbol">
            <div class="bgmodel flex justify-center items-center">
              <img src="../../assets/qqewm.png" class="w-[9.175rem]" alt="">
            </div>
          </div>

          <div class="flex justify-center w-full mt-[12rem]">
            <img src="../../assets/pc/sq/title.png" class="w-[8.5rem] h-[5.0625rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[21.9375rem] " @click="ongolink('mrzx')"
            @mouseenter="mouseenter(4)" @mouseleave="mouseleave(4)">
            <img src="../../assets/pc/sq/mrzx.png" class="w-[9.1875rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[35.125rem] " @mouseenter="mouseenter(1)"
            @mouseleave="mouseleave(1)">
            <img src="../../assets/pc/sq/dy.png" class="w-[5.5625rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[46.25rem]" @click="ongolink('wb')">
            <img src="../../assets/pc/sq/wb.png" class="w-[5.5625rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[57.3125rem]" @mouseenter="mouseenter(2)"
            @mouseleave="mouseleave(2)">
            <img src="../../assets/pc/sq/wx.png" class="w-[5.5625rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[68.4375rem]" @mouseenter="mouseenter(3)"
            @mouseleave="mouseleave(3)">
            <img src="../../assets/pc/sq/qq.png" class="w-[5.5625rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[77.75rem]" @click="ongolink('bl')">
            <img src="../../assets/pc/sq/bl.png" class="w-[9.25rem] h-[9.5rem]" alt="">
          </div>
          <div class="absolute top-[29.125rem] cursor-pointer left-[89.3125rem]" @click="ongolink('tap')">
            <img src="../../assets/pc/sq/tap.png" class="w-[8.4375rem] h-[9.5rem]" alt="">
          </div>
        </div>
      </div>
      <div class="section footer">
        <div class="flex justify-center items-center">
          <footerpage></footerpage>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import menupage from '../../components/pc/newmenupage.vue'
import leftmenu from '../../components/pc/leftmenu.vue'
import rightfixed from '../../components/pc/rightfixed.vue'
// news
import newspage from '../../components/pc/news/index.vue'
import footerpage from '../../components/pc/footer/index.vue'
import axios from 'axios'
import qs from 'qs'
export default {
  name: 'indexPage',
  components: {
    menupage,
    leftmenu,
    rightfixed,
    newspage,
    footerpage
  },
  data() {
    return {
      ismrzx: true,
      wxbol: false,
      qqbol: false,
      dybol: false,
      mrzxbol: false,
      // 视听数据
      stshowdata: [
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st1.mp4',
          path: require('../../assets/pc/st/sc/video/st1fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st2.mp4',
          path: require('../../assets/pc/st/sc/video/st2fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st3.mp4',
          path: require('../../assets/pc/st/sc/video/st3fm.jpg')
        },
        {
          video: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/st4.mp4',
          path: require('../../assets/pc/st/sc/video/st4fm.jpg')
        },
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/1.png')
        },
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/2.png')
        }, {
          video: '',
          path: require('../../assets/pc/st/sc/img/3.png')
        }, {
          video: '',
          path: require('../../assets/pc/st/sc/img/4.png')
        },
        {
          video: '',
          path: require('../../assets/pc/st/sc/img/5.jpg')
        }
      ],
      // 游戏特色数据
      tsindex: 0,
      options: {
        afterLoad: this.afterLoad,
      },
      isvideo: false,
      showvideo: '',
      isimgpath: false,
      showimgpath: '',
      // amnation
      disabled: false,

      tslist: [
        {
          name: require('../../assets/pc/st/tsimg/1.jpg')
        },
        {
          name: require('../../assets/pc/st/tsimg/2.jpg')
        },
        {
          name: require('../../assets/pc/st/tsimg/3.jpg')
        },
        {
          name: require('../../assets/pc/st/tsimg/4.jpg')
        },
        {
          name: require('../../assets/pc/st/tsimg/5.jpg')
        }
      ],
      rolelist: [
        {
          name: '狂战士 加里安',
          content: '背负亡女之仇的黑暗战士，使用斧头的狂战士拥有极高的攻击力，擅长近身搏杀，能迅速接近并消灭敌人，也能引诱敌人并施展猛烈一击。',
          imgpage: require('../../assets/pc/role/userkz.png'),
          role: require('../../assets/pc/role/kzrole.png'),
          icon: require('../../assets/pc/role/kzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/kzs.mp4',
          videofm: require('../../assets/pc/role/kzfm.png')
        },

        {
          name: '守护骑士 泰文',
          content: '寻找丢失记忆的守护骑士，使用锤子和盾牌作为武器，拥有极高防御力，在降低敌人伤害的同时，能将敌人聚集到一起并发动猛攻。',
          imgpage: require('../../assets/pc/role/usershqs.png'),
          role: require('../../assets/pc/role/shqsrole.png'),
          icon: require('../../assets/pc/role/shqsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/shqs.mp4',
          videofm: require('../../assets/pc/role/shqsfm.png')
        },
        {
          name: '魔法师 丽娅',
          content: '痴迷于神秘魔法的天才魔法师，使用法环作为武器，擅长远程攻击，使用着曾经不被允许的魔法进行战斗，聚气之后可以用更强的攻击打倒敌人。',
          imgpage: require('../../assets/pc/role/userfs.png'),
          role: require('../../assets/pc/role/fsrole.png'),
          icon: require('../../assets/pc/role/fsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/mfs.mp4',
          videofm: require('../../assets/pc/role/mfsfm.png')
        },
        {
          name: '潜行者 内梅斯',
          content: '深受误解却依然正义的潜行者，使用短剑作为武器，是近身灵活缠斗的专家，能用各种技能削弱敌人，然后敏捷地发动攻击。',
          imgpage: require('../../assets/pc/role/userqxz.png'),
          role: require('../../assets/pc/role/qxzrole.png'),
          icon: require('../../assets/pc/role/qxzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/qxz.mp4',
          videofm: require('../../assets/pc/role/qxzfm.png')
        },
        {
          name: '弓箭手 蒂埃拉',
          content: '劫富济贫的侠盗团团长养女，专精于弓箭，擅长灵活的远程战斗，能迅速回避敌人的攻击，并敏锐地使用箭矢反击。',
          imgpage: require('../../assets/pc/role/usergjs.png'),
          role: require('../../assets/pc/role/gjsrole.png'),
          icon: require('../../assets/pc/role/gjsicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/gjs.mp4',
          videofm: require('../../assets/pc/role/gjsfm.png')
        },
        {
          name: '学者 赛尔菲',
          content: '用清晰的头脑研究知识的学者，使用4种召唤术形态的技能，可近战也可远攻，根据技能组合展开全方位的攻击是她的特色。',
          imgpage: require('../../assets/pc/role/userxz.png'),
          role: require('../../assets/pc/role/xzrole.png'),
          icon: require('../../assets/pc/role/xzicon.png'),
          videopath: 'https://a3.game.nextjoy.com/a3_web/xczvideo/new/xz.mp4',
          videofm: require('../../assets/pc/role/xzfm.png')
        }
      ],
      // 下载链接
      xzlink: '',
    }
  },
  computed: {
    propsmenuindex() {
      return this.$store.state.menuindex
    }
  },
  watch: {
    // 菜单切换
    propsmenuindex: function (val) {
      // console.log('监听器', val)
      switch (val) {
        case 0:
          this.$refs.fullpage.api.moveTo(1, 0)
          const videoElement = this.$refs.videoid;
          // 检查视频元素是否存在以及当前视频是否已经加载
          if (videoElement && videoElement.readyState >= 2) {
            // 播放视频
            videoElement.play();
          }
          break
        case 1:
          this.$refs.fullpage.api.moveTo(2, 1)
          break
        case 2:
          this.$refs.fullpage.api.moveTo(3, 2)
          break
        case 3:
          this.$refs.fullpage.api.moveTo(4, 3)
          break
        case 4:
          this.$refs.fullpage.api.moveTo(5, 4)
          break
        case 5:
          this.$refs.fullpage.api.moveTo(6, 5)
          break
      }
    }
  },
  created() {
  },
  mounted() {
    // 根据参数跳转
    if (this.$route.query.menuid) {
      console.log(this.$route.query.menuid)
      let index = Number(this.$route.query.menuid)
      this.$store.commit('setmenuindex', index)
    }
    // this.dowloadlink()
  },
  methods: {
    dowloadlink() {
      axios({
        method: 'post',
        url: 'https://sdk.nextjoy.com/channel/package/get_download_url',
        data: qs.stringify({
          channelPackageId: this.$route.query.subchannel,
          mercId: this.$route.query.merchID
        })
      }).then(res => {
        this.xzlink = res.data.data.downloadUrl
        // 下载
        const a = document.createElement("a");
        a.href = this.xzlink;
        a.click();
        window.URL.revokeObjectURL(this.xzlink);
        a.remove();
      })
    },
    // 下载
    goappfun(item) {
      if (item == 'apple') {
        window.open('https://apps.apple.com/cn/app/id1573385372', '_blank')
      } else if (item == 'tap') {
        window.open('https://l.tapdb.net/3PVEzYfe?channel=rep-rep_ajzngmj2346', '_blank')
      } else if (item == 'ld') {
        let xzlink = 'https://lddl01.ldmnq.com/downloader/ldplayerinst9.exe?n=LDplayer9_com.nextjoy.stilla_401298_ld.exe'
        // 下载
        const a = document.createElement("a");
        a.href = xzlink;
        a.click();
        window.URL.revokeObjectURL(xzlink)
        a.remove();
      } else if (item == 'mumu') {
        let xzlink = 'https://a11.gdl.netease.com/MuMuInstaller_3.1.5.0_yx-zzrxcz_zh-Hans_1691747946.exe'
        // 下载
        const a = document.createElement("a");
        a.href = xzlink;
        a.click();
        window.URL.revokeObjectURL(xzlink)
        a.remove();
      } else {
        // 预约
        // window.open('https://a3page.nextjoy.com/', '_blank')
        // 下载
        this.dowloadlink()
      }
    },
    download() {
      const ua = navigator.userAgent.toLowerCase();//获取判断用的对象
      let isos = '1'
      // if (ua.indexOf('android') >= 0) {
      //   console.log('进入测试')
      //   isos = '1'
      // } else {
      //   isos = '2'
      // }
      axios({
        method: 'post',
        url: '//api-bbs.nextjoy.com.cn/common/getDownloadUrl',
        data: qs.stringify({
          os: '1',
          bundle_id: 'com.nextjoy.star'
        })
      }).then(res => {
        console.log('res', res)
        if (res.data.code === 200) {
          let xzlink = res.data.data.download_url
          const a = document.createElement("a");
          a.href = xzlink;
          a.click();
          window.URL.revokeObjectURL(xzlink)
          a.remove();
        } else {
          console.log(res.data.message)
        }
      })
    },
    // 底部链接
    ongolink(item) {
      if (item == 'wb') {
        window.open('https://weibo.com/u/7810973153', '_blank')
      } else if (item == 'bl') {
        window.open('https://space.bilibili.com/3493140740704897', '_blank')
      } else if (item == 'tap') {
        window.open('https://l.tapdb.net/3PVEzYfe?channel=rep-rep_ajzngmj2346', '_blank')
      } else if (item == 'mrzx') {
        this.download()

        // 下载
        // let xzlink = 'https://download-bbs.nextjoy.com.cn/android/mrzx_1.1.0.apk'
        // const a = document.createElement("a");
        // a.href = xzlink;
        // a.click();
        // window.URL.revokeObjectURL(xzlink)
        // a.remove();
      }
    },
    mouseenter(index) {
      if (index == 1) {
        this.dybol = true
      } else if (index == 2) {
        this.wxbol = true
      } else if (index == 4) {
        this.mrzxbol = true
      } else {
        this.qqbol = true
      }
    },
    mouseleave(index) {
      if (index == 1) {
        this.dybol = false
      } else if (index == 2) {
        this.wxbol = false
      } else if (index == 4) {
        this.mrzxbol = false
      } else {
        this.qqbol = false
      }
    },
    ontsleft() {
      console.log('数据', this.tsindex)
      this.tsindex = this.tsindex - 1
      console.log(this.tsindex)
      if (this.tsindex == -1) {
        this.tsindex = 4
      }
    },
    ontsright() {
      this.tsindex = this.tsindex + 1
      if (this.tsindex == 5) {
        this.tsindex = 0
      }
    },
    // 视听
    onplayimg(item) {
      console.log('item')
      this.showimgpath = item
      this.isimgpath = true
    },
    getRotation(el) {
      var st = window.getComputedStyle(el, null);
      var tm = st.getPropertyValue("-webkit-transform") ||
        st.getPropertyValue("-moz-transform") ||
        st.getPropertyValue("-ms-transform") ||
        st.getPropertyValue("-o-transform") ||
        st.getPropertyValue("transform") ||
        "none";
      if (tm != "none") {
        var values = tm.split('(')[1].split(')')[0].split(',');
        var a = values[0];
        var b = values[1];
        var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        return (angle < 0 ? angle + 360 : angle);
      }
      return 0;
    },
    onleft() {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 300);
      let removedItem = this.rolelist.pop();
      console.log(removedItem)
      this.rolelist.unshift(removedItem)
      var circle = document.getElementById('circle');
      var currentRotation = this.getRotation(circle);
      // var currentRotation = 0
      circle.style.transform = `rotateZ(${currentRotation + 60}deg)`;
    },
    onright() {
      this.disabled = true
      setTimeout(() => {
        this.disabled = false
      }, 300);
      let removedItem = this.rolelist.shift();
      console.log(removedItem)
      this.rolelist.push(removedItem)
      var circle = document.getElementById('circle');
      var currentRotation = this.getRotation(circle);
      // var currentRotation = 0
      circle.style.transform = `rotateZ(${currentRotation - 60}deg)`;
    },
    onplayvideo(item) {
      this.showvideo = item
      this.isvideo = true
    },
    // 轮播图
    afterLoad(origin, destination) {
      console.log(origin, destination)
      this.$store.commit('setmenuindex', destination.index)
      // origin 起点 destination终点 direction方向 固定写法
      // this.navIndex = destination.index;
      // this.tabIndex = 0
      // this.rigthImg = this.tabImgList[0].rightCont
    },
  }
}
</script>

<style scoped>
.mrzxtitle {
  font-size: 1.1875rem;
  font-family: Source Han Serif SC;
  font-weight: 600;
  color: #FFFFC0;
  -webkit-text-stroke: 1px #050729;
  text-stroke: 1px #050729;
}

.mrzxtext {

  font-size: .8125rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.72);
  line-height: 1.3125rem;
}

.bgewm {
  background: url(../../assets//pc/xz/masterbut/mrzx/ermbg.png) no-repeat;
  background-size: 100% 100%;
}

.bgmrzx {
  background: url(../../assets/pc/xz/masterbut/mrzx/mrzxbg.png) no-repeat;
  background-size: 100% 100%;
}

.ewmimg {
  background: url(../../assets/pc/xz/masterbut/ewmnews.png) no-repeat;
  background-size: 100% 100%;
}

.textsm {
  writing-mode: vertical-lr
}

.mrzxbg {
  background: url(../../assets/pc/xz/masterbut/mrzxbg.png) no-repeat;
  background-size: 100% 100%;
}

.footer {
  height: 15vh !important;
  background: #000;
}

.bgmodel {
  width: 10rem;
  height: 13.1875rem;
  background: url(../../assets/modebg.png) no-repeat;
  background-size: 100% 100%;
}

/* 视听 */
.image-zoom {
  transition: background-size .2s, background-position .2s;
  /* transition: transform .2s; */
  /* Animation */
  /* margin: 0 auto; */
}

.image-zoom:hover {
  /* background-size: 120%;
  background-position: center; */
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.clearfix::before {
  content: '';
  display: table;
}

.tstitle {
  width: 14.3125rem;
  height: 3.125rem;
  /* line-height: 17px; */
  /* font-size: 3.1875rem;
  font-family: Source Han Serif SC;
  font-weight: 800;
  color: #394E52; */
  /* line-height: 5.4375rem; */
}

/* 视频背景 */
.video-box {
  position: relative;
  height: 100vh;
  background-color: rgb(4, 4, 5);
  background: url(../../assets/pc/bg_01.jpg) no-repeat;
  background-size: 100% 100%;
  /*进行视频裁剪*/
  overflow: hidden;
}

.video-box .video-background {
  position: absolute;
  left: 50%;
  top: 50%;
  /*保证视频内容始终居中*/
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  /*保证视频充满屏幕*/
  object-fit: cover;
  min-height: 50rem;
}

/* 角色动画 */
.textanimation {
  animation-name: textdh;
  animation-duration: 2s;
}

@keyframes textdh {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#circle {
  transition: transform 2s ease;
}

.bg_rightbg {
  background: url(@/assets/pc/newsimg/rightbg.png) no-repeat;
  background-size: 100% 100%;
}

.bg_leftbg {
  background: url(@/assets/pc/newsimg/leftbg.png) no-repeat;
  background-size: 100% 100%;
}

.bg_02 {
  background: url(../../assets/pc/bg_02.png) no-repeat;
  background-size: 100% 100%;
}

.bg_03 {
  background: url(../../assets/pc/bg_03.png) no-repeat;
  background-size: 100% 100%;
}

.bg_04 {
  background: url(../../assets/pc/bg_04.png) no-repeat;
  background-size: 100% 100%;
}

.bg_05 {
  background: url(../../assets/pc/bg_05.png) no-repeat;
  background-size: 100% 100%;
}

.bg_06 {
  background: url(../../assets/pc/bg_06.png) no-repeat;
  background-size: 100% 100%;
}


/* download */
.downloadbox {
  background: url(../../assets/pc/bgbox.png) no-repeat;
  background-size: 100% 100%;
}

.slogantext {
  /* width: 333px;
  height: 25px; */
  /* font-size: 1.5625rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: rgba(246, 217, 173, 0.77); */
  /* color:#F6D9AD */
  /* line-height: 52px; */
  /* text-shadow: 0px 0px 5px #000000; */
  /* width: 524px;
height: 28px; */
  font-size: 1.375rem;
  font-family: IdeaFonts;
  font-weight: normal;
  color: #FFFFFF;

  background: linear-gradient(180deg, #FFFFFF 0%, #EAEAEA 60.44921875%, #ACACAC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rolemessage {
  font-family: Source Han Serif SC;
}

/* 子标题 */
.smallslogan {
  background: url(../../assets/pc/smallslogan.png);
  background-size: 100% 100%;
}

/* scroll */
.gamelogo {
  animation-name: move;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes move {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
}

@-webkit-keyframes move {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
}</style>
