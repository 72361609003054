<!-- <script setup>
import { onMounted, ref } from 'vue';

onMounted(() => {
    geturlpage()
})
const isfooter = ref(true)
function footerxx(item) {
    if (item == '1') {
        window.open('https://sdk.nextjoy.com/init/init/getPrivacy?sdkver=3.0.0', '_blank');
    } else if (item == '2') {
        window.open('https://sdk.nextjoy.com/init/init/getRegster', '_blank');
    }
}
function goicp() {
    window.open('https://beian.miit.gov.cn', '_blank');
}

const isfooterTy = ref(false)
function geturlpage() {

    if (window.location.host == 'a3.bjnextworld.com' || window.location.host == 'wjmt.nextworld.net.cn' || window.location.host == 'wjmt.bjnextworld.com' || window.location.host == 'bjnextworld.com' || window.location.host == 'nextworld.net.cn' || window.location.host == 'www.nextworld.net.cn') {
        isfooterTy.value = false
        isfooter.value = false
    } else if (window.location.host == 'wjmt.nextjoy.com.cn') {
        isfooterTy.value = false
        isfooter.value = true
    } else if (window.location.host == 'xczgame.nextjoy.com' || window.location.host == 'xczgame.nextjoy.com') {
        isfooterTy.value = true
        isfooter.value = false
    } else {
        isfooterTy.value = false
        isfooter.value = true
    }

    console.log(' window.location.host', window.location.host)
}


defineProps(['colorvalue'])

</script> -->

<template>
    <div>
        <div class=" h-[12vh] flex justify-center  py-[1rem]" :style="{ backgroundColor: colorvalue }" v-if="isfooter">
            <div class="flex flex-col items-center justify-center ">
                <div><img src="./img/foot2.png" class="w-[6.875rem] h-[1.75rem]" alt="logo"></div>
                <div class="text cursor-pointer mt-1 text-center"><span @click="footerxx(1)">隐私协议</span> | <span
                        @click="footerxx(2)">用户协议</span></div>
            </div>
            <div class="flex flex-col ml-[2rem] mt-[1rem]">
                <div class="text"><span>明日世界（上海）网络科技有限公司</span></div>
                <div class="text align-middle ">
                    <img src="./img/beian.png" class="w-[1rem] h-[1rem] inline-block" alt="公安logo">公安备案号:31010502004459号
                </div>
                <div class="text cursor-pointer"><span @click="goicp()">沪ICP备17036270号-3</span></div>

            </div>
            <div class="mt-[1rem] ml-[2rem]">
                <div class="text"><span>增值电信业务经营许可证：沪B2-20170436</span></div>
                <div class="text align-middle">
                    <img src="./img/footga.png" class="w-[1rem] h-[1rem] inline-block" alt="网文logo">网络文化经营许可证：沪网文
                    （2020）3528-249号
                </div>
                <div class="text"><span>ⓒ Netmarble Corp. & Netmarble N2 Inc. All Rights Reserved.</span></div>
            </div>
        </div>
        <div class=" h-[12vh] flex justify-center  py-[1rem]" :style="{ backgroundColor: colorvalue }" v-if="isfooterbj">
            <div class="flex flex-col items-center justify-center">
                <div><img src="./img/foot2.png" class="w-[6.875rem] h-[1.75rem]" alt="logo"></div>
                <div class="text cursor-pointer mt-1 text-center"><span @click="footerxx(1)">隐私协议</span> | <span
                        @click="footerxx(2)">用户协议</span>
                </div>
            </div>
            <div class="flex flex-col ml-[2rem] mt-[1rem]">
                <div class="text"><span>明日世界（北京）科技有限公司 京B2-20224967</span></div>
                <!-- <div class="text align-middle ">
                    <img src="./img/beian.png" class="w-[1rem] h-[1rem] inline-block" alt="公安logo">公安备案号:31010502004459号
                </div> -->
                <div class="text cursor-pointer"><span @click="goicp()">京ICP备2022024502号-3</span></div>
                #ICP=20220245025-3

            </div>
            <!-- <div class="mt-[1rem] ml-[2rem]">
                <div class="text"><span>增值电信业务经营许可证：沪B2-20170436</span></div>
                <div class="text align-middle">
                    <img src="./img/footga.png" class="w-[1rem] h-[1rem] inline-block" alt="网文logo">网络文化经营许可证：沪网文
                    （2020）3528-249号
                </div>
            </div> -->
        </div>
        <div class=" h-[12vh] flex justify-center  py-[1rem]" :style="{ backgroundColor: colorvalue }" v-if="isfooterTy">
            <div class="flex flex-col items-center justify-center">
                <div><img src="./img/foot2.png" class="w-[6.875rem] h-[1.75rem]" alt="logo"></div>
                <div class="text cursor-pointer mt-1 text-center"><span @click="footerxx(1)">隐私协议</span> | <span
                        @click="footerxx(2)">用户协议</span></div>
            </div>
            <div class="flex flex-col ml-[2rem] mt-[1rem]">
                <div class="text"><span>明日世界竞技体育发展有限公司</span></div>
                <div class="text align-middle ">
                    <img src="./img/beian.png" class="w-[1rem] h-[1rem] inline-block" alt="公安logo">公安备案号:31010502004605
                </div>
                <div class="text cursor-pointer"><span @click="goicp()">沪ICP备16045192号-1</span></div>

            </div>
            <!-- <div class="mt-[1rem] ml-[2rem]">
                <div class="text"><span>增值电信业务经营许可证：沪B2-20170436</span></div>
                <div class="text align-middle">
                    <img src="./img/footga.png" class="w-[1rem] h-[1rem] inline-block" alt="网文logo">网络文化经营许可证：沪网文
                    （2020）3528-249号
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    name: 'pcfooter',
    data() {
        return {
            isfooter: true,
            isfooterTy: false,
            isfooterbj:false
        }
    },
    props:['colorvalue'],
    created() {
        this.geturlpage()
    },
    methods: {
        goicp() {
            window.open('https://beian.miit.gov.cn', '_blank');
        },
        geturlpage() {
            console.log('go pc if')
            if (window.location.host == 'a3.bjnextworld.com' || window.location.host == 'wjmt.nextworld.net.cn' || window.location.host == 'wjmt.bjnextworld.com' || window.location.host == 'bjnextworld.com' || window.location.host == 'nextworld.net.cn' || window.location.host == 'www.nextworld.net.cn') {
                this.isfooterTy = false
                this.isfooter = false
                this.isfooterbj = true
            } else if (window.location.host == 'wjmt.nextjoy.com.cn') {
                this.isfooterTy = true
                this.isfooter = false
                this.isfooterbj = false
            } else if (window.location.host == 'xczgame.nextjoy.com' || window.location.host == 'xczgame.nextjoy.com') {
                this.isfooterTy = true
                this.isfooter = false
                this.isfooterbj = false
            } else {
                this.isfooterTy = true
                this.isfooter = true
                this.isfooterbj = false
            }
            console.log(' window.location.host', window.location.host)
        },
        footerxx(item) {
            if (item == '1') {
                window.open('https://sdk.nextjoy.com/init/init/getPrivacy?sdkver=3.0.0', '_blank');
            } else if (item == '2') {
                window.open('https://sdk.nextjoy.com/init/init/getRegster', '_blank');
            }
        }

    }
}
</script>
<style scoped>
.text {
    font-size: 0.667rem;
    color: rgb(140, 140, 140)
}
</style>
