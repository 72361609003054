<template>
  <div>
    <div class="flex items-center justify-center w-full h-[100vh] relative">
      <!-- 轮播图 -->
      <div class="w-[43.875rem] h-[30.5625rem] bg-black z-[1]">
        <el-carousel @change="handleChange" ref="myCarousel" indicator-position="none" height="30.5625rem"
          setActiveItem="setactiveitem">
          <el-carousel-item v-for="item in srcList" :key="item">
            <img :src="item" class=" h-full w-full" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图切换按钮 -->
      <div class="absolute top-[45.3rem] left-[33.9375rem] flex" v-if="true">
        <div class="cursor-pointer" v-for="(item, index) in  srcList.length" @click="switchToSecondItem(index)">
          <img src="@/assets/pc/newsimg/no.png" class="w-[2.125rem] h-[2.125rem]" alt="" v-if="index == activeIndex">
          <div class="w-[2.125rem] h-[2.125rem] flex justify-center items-center">
            <img src="@/assets/pc/newsimg/off.png" class="w-[1.25rem] h-[1.125rem]" alt="" v-if="index != activeIndex">
          </div>
        </div>
      </div>

      <!-- 新闻列表 -->
      <div class="w-[44.875rem] h-[30.5625rem] bg-[rgb(24,24,24)] z-[0] relative">
        <div class="flex justify-start mt-[3rem] ml-[2.9rem]">
          <div @click="onnewsfun(item, index)" v-for="(item, index) in newslist"
            class="w-[8.1875rem]  flex justify-center flex-col items-center relative cursor-pointer">
            <span class="titletext " :class="newsindex == index ? 'text-[#E0C595]' : 'text-[#7F7D77]'">{{ item.name
            }}</span>
            <img v-if="newsindex == index" src="@/assets/pc/newsimg/hover.png"
              class="absolute w-[8.1875rem] h-[6.9375rem] top-[-0.9rem] object-contain" alt="">
          </div>
          <div @click="onshowmore()" class="mt-2 right-11 absolute cursor-pointer"><span
              class="text-[#7F7D77] text-[1.125rem]">查看更多</span></div>
        </div>
        <!-- 下划线 -->
        <div class="flex justify-center mt-[.85rem]">
          <div><img src="@/assets/pc/newsimg/hr.png" class="w-[39.25rem] h-[.125rem]" alt=""></div>
        </div>
        <div class="mt-[2.625rem] flex gap-[1.5rem] justify-center flex-col items-center ">
          <div v-for="item in newscontent.slice(0, 5)" @click="shownewstext(item)">
            <div class="w-[36.75rem] flex justify-between text-[#908D88] text-[1.25rem] cursor-pointer">
              <div class=" w-[30.8125rem] overflow-hidden whitespace-nowrap text-ellipsis">{{ item.title }}</div>
              <div class=" text-[1rem] ml-1" v-if="item.start_time && typeof item.start_time === 'string'">
                {{ item.start_time.slice(5, 10) }}
              </div>
            </div>
            <div class="mt-2">
              <img src="@/assets/pc/newsimg/contenthr.png" class="w-[39.25rem] h-[.0625rem]" alt="">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs'
export default {
  name: 'newsPage',
  data() {
    return {

      srcList: [
        require('@/assets/pc/st/imglist/1.jpg'),
        require('@/assets/pc/st/imglist/2.jpg'),
      ],
      newsindex: 0,
      newslist: [
        {
          name: '最新'
        },
        {
          name: '新闻'
        },
        {
          name: '公告'
        },
        {
          name: '活动'
        },
      ],
      newscontent: [
        {
          name: '《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        }
      ],
      activeIndex: '',

    }
  },

  created() {
    this.getlist()
  },
  methods: {

    // 查看详情
    shownewstext(item) {
      console.log('test', item, this.$route.query.subchannel)
      if (this.$route.query.merchID) {
        let subchannel = this.$route.query.subchannel
        let merchID = this.$route.query.merchID
        window.open(`https://a3.nextjoy.com.cn/news/newscontent.html?article_id=${item.id}&subchannel=${subchannel}&merchID=${merchID}`, '_blank')
      } else {
        window.open(`https://a3.nextjoy.com.cn/news/newscontent.html?article_id=${item.id}`, '_blank')
      }

      // if(this.$route.query.subchannel && this.$route.query.subchannel)

    },
    handleChange(index) {
      this.activeIndex = index;
      console.log('当前活动项的索引是：', index);
    },
    getlist() {
      // 渲染新闻数据
      axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
        params: {
          pubkey: "3253942b24c7ad7eca566bf92696e1a6",
          page: 1,
          limit: '5',
          // type_id:'50'
        }
      }).then(res => {
        console.log('init', res)
        this.newscontent = res.data.data.time_news
        // this.$store.commit('setmewsmain', res.data.data.time_news)
      })
    },
    setType(index) {
      let type;
      if (index == 1) {
        type = 50;
      } else if (index == 2) {
        type = 51;
      } else if (index == 3) {
        type = 52;
      }
      return type;
    },
    onnewsfun(item, index) {
      this.newsindex = index
      if (index == '0') {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: 1,
            limit: '10',
            // type_id:'50'
          }
        }).then(res => {
          console.log('init', res)
          this.newscontent = res.data.data.time_news
        })
      } else {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: 1,
            limit: '10',
            type_id: this.setType(index)
          }
        }).then(res => {
          // 装载news.data 数据
          this.newscontent = res.data.data.news.data
        })
      }
    },
    switchToSecondItem(index) {
      this.$refs.myCarousel.setActiveItem(index);
    },
    
    // 查看更多
    onshowmore() {
      if (this.$route.query.merchID) {
        let subchannel = this.$route.query.subchannel
        let merchID = this.$route.query.merchID
        window.open(`https://a3.nextjoy.com.cn/news/index.html?subchannel=${subchannel}&merchID=${merchID}`, '_blank')
      } else {
        window.open(`https://a3.nextjoy.com.cn/news/index.html`, '_blank')
      }

    }
  },
  components: {
  }
}
</script>

<style scoped>
.titletext {
  font-size: 1.6331rem;
  font-family: 'zihun95hao-shoukesong', sans-serif;
  font-family: Source Han Serif SC;
  /* color: #E0C595; */
}

.bg_rightbg {
  background: url(@/assets/pc/newsimg/rightbg.png) no-repeat;
  background-size: 100% 100%;
}

.bg_leftbg {
  background: url(@/assets/pc/newsimg/leftbg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
