<template>
  <div class="fixed left-[5.375rem] top-[14.875rem] z-[1000]">
    <!-- 测试文件内容
    {{ propsmenuindex }} -->
    <div class="flex flex-col justify-start items-start" v-show="propsmenuindex != 4">
      <div class="ml-2">
        <img src="../../assets/pc/menuleft/topimg.png" alt="" class="w-[1.4375rem] h-[10.1875rem]">
      </div>
      <div v-for="(item, index) in menulist" class="flex items-center cursor-pointer" @click="onmenufun(item, index)">
        <div class="w-[2.5rem] h-[2.5rem] flex justify-center items-center">
          <img src="../../assets/pc/menuleft/no.png" class="w-[1.125rem] h-[1.125rem]" alt=""
            v-if="propsmenuindex != index">
          <img src="../../assets/pc/menuleft/off.png" class="w-[2.5rem] h-[2.5rem]" alt="" v-if="propsmenuindex == index">
        </div>
        <div>
          <span class="menutext opacity-[0.6]" :class="propsmenuindex == index ? 'opacity-[1]':''">{{ item.name }}</span>
        </div>
      </div>
      <div class="ml-2">
        <img src="../../assets/pc/menuleft/bimg.png" alt="" class="w-[1.4375rem] h-[10.1875rem]">
      </div>
    </div>
    <div class="flex flex-col justify-start items-start" v-show="propsmenuindex == 4">
      <div class="ml-2">
        <img src="../../assets/pc/menuleft/topimg1.png" alt="" class="w-[1.4375rem] h-[10.1875rem]">
      </div>
      <div v-for="(item, index) in menulist" class="flex items-center cursor-pointer" @click="onmenufun(item, index)">
        <div class="w-[2.5rem] h-[2.5rem] flex justify-center items-center">
          <img src="../../assets/pc/menuleft/no1.png" class="w-[1.125rem] h-[1.125rem]" alt=""
            v-if="propsmenuindex != index">
          <img src="../../assets/pc/menuleft/off1.png" class="w-[2.5rem] h-[2.5rem]" alt="" v-if="propsmenuindex == index">
        </div>
        <div>
          <span class="menutext1 opacity-[0.6]" :class="propsmenuindex == index ? 'opacity-[1]':''">{{ item.name }}</span>
        </div>
      </div>
      <div class="ml-2">
        <img src="../../assets/pc/menuleft/bimg1.png" alt="" class="w-[1.4375rem] h-[10.1875rem]">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'leftMenu',
  data() {
    return {
      menulist: [
        {
          name: '首页'
        },
        {
          name: '资讯'
        },
        {
          name: '职业介绍'
        },
        {
          name: '视听中心'
        },
        {
          name: '游戏特色'
        },
        {
          name: '社群'
        }
      ],
     
    }
  },
  components: {

  },
  methods:{
    onmenufun(item,index){
      this.$store.commit('setmenuindex', index)
    }
  },
  computed: {
    propsmenuindex() {
      return this.$store.state.menuindex
    }
  }
}
</script>

<style scoped>
.menutext {
  /* width: 38px;
  height: 19px; */
  font-size: 1.1875rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: #F7EDDE;
  line-height: 1.625rem;
}
.menutext1 {
  /* width: 38px;
  height: 19px; */
  font-size: 1.1875rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: #394E52;
  line-height: 1.625rem;
}
</style>
