<template>
  <div class="fixed top-0 headernewbg  w-full h-[4.25rem]  z-[1000]">
    <div class=" absolute left-[24.1875rem] h-full flex items-center">
      <a href="https://game.nextjoy.com/" target="_blank">
        <img src="../../assets/publicmenu/logo.png" class="w-[8.5625rem] h-[2rem]" alt="">
      </a>
    </div>
    <div class=" absolute left-[33.25rem] h-full flex items-center">
      <img src="../../assets/publicmenu/hr1.png" class="w-[.0625rem] h-[1.625rem]" alt="">
    </div>
    <div class="flex absolute left-[34.4375rem] h-full items-center">
      <a href="https://mrzx-bbs.bjnextworld.com/pc/" target="_blank">
        <img src="../../assets/publicmenu/sqname.png" class="w-[9.375rem] h-[2.125rem]" alt="">
      </a>
    </div>
    <div class="flex items-center h-full absolute left-[58.6875rem] cursor-pointer">
      <span class="text-[.9375rem] font-[100] font-[italic] text-[#FFFFFF] hover:text-[#e78b83] titlezt">
        <a href="https://game.nextjoy.com/" target="_blank">明日世界游戏</a>
      </span>
    </div>
    <div class="flex items-center h-full absolute left-[70.375rem] cursor-pointer">
      <span class="titlezt text-[1rem] text-[#FFFFFF] hover:text-[#EE5647]">
        <a href="https://mrzx-bbs.bjnextworld.com/pc/" target="_blank">官方社区</a>
      </span>
    </div>
    <div class="flex items-center h-full absolute left-[82.0625rem] cursor-pointer " @click="isgamelist = !isgamelist">
      <span class="titlezt flex items-center text-[1rem]   hover:text-[#EE5647] namehover"
        :class="isgamelist ? 'text-[#EE5647]' : 'text-[#FFFFFF]'">
        明日世界游戏
        <div class="w-[.75rem] h-[.8125rem] ml-[.25rem]" :class="isgamelist ? 'iconbghover' : 'iconbg'"></div>
      </span>
    </div>
    <!-- 下拉框 -->
    <div class="flex justify-start flex-col items-start w-[14.75rem]  absolute left-[79.625rem] top-[4.25rem] gamelistbg"
      v-if="isgamelist">
      <div class="flex items-center ml-[2.4375rem] mt-[2.3125rem]">
        <img src="../../assets/publicmenu/hr.png" class="w-[.125rem] h-[1.4375rem]" alt="">
        <span class="text-[#FFFFFF] text-[1.25rem] ml-[.625rem] gamelisttitle">手机游戏</span>
      </div>
      <div class="mt-[1.875rem]">
        <div v-for="item in gamelist" class="ml-[3.125rem] mb-[1.125rem]">
          <span class="gamelistnamme  text-[.9375rem] opacity-[0.6] text-[#FFFFFF] cursor-pointer hover:text-[#D24536]">
            <a :href="item.path" target="_blank">{{ item.gamename }}</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menuPage',
  data() {
    return {
      gamelist: [
        {
          gamename: '我叫MT：经典再现',
          path: 'https://wjmt.nextjoy.com.cn/'
        },
        {
          gamename: '战之刃：幸存者',
          path: 'https://a3.nextjoy.com.cn/'
        },
        {
          gamename: '哆啦A梦飞车',
          path: 'https://doraemonspeed.nextjoy.com.cn/'
        },
        {
          gamename: '驯龙高手：旅程',
          path: 'https://xlgs.nextjoy.com.cn/'
        }
      ],
      isgamelist: false,

    }
  },
  computed: {

  },
  methods: {
    onmenufun(item, index) {
      // this.menuindex = index
      this.$store.commit('setmenuindex', index)
    }
  },
  components: {

  }
}
</script>

<style scoped>
.namezt {
  font-family: zihun176hao-chuangcuyuan;
  font-weight: 400;
  font-style: italic;
  color: #FFFFFF;
}

.titlezt {
  font-family: PingFang SC;
  font-weight: 400;
}

.gamelisttitle {
  font-family: PingFang SC;
  font-weight: 300;
}

.gamelistnamme {
  font-family: PingFang SC;
  font-weight: 400;
}

.namehover:hover .iconbg {
  background: url(../../assets/publicmenu/gllogo.png) no-repeat;
  background-size: 100% 100%;
}

.iconbghover {
  background: url(../../assets/publicmenu/gllogo.png) no-repeat;
  background-size: 100% 100%;
}

.iconbg {
  background: url(../../assets/publicmenu/glogooff.png) no-repeat;
  background-size: 100% 100%;
}

.gamelistbg {
  background: url(../../assets/publicmenu/gamelistbg.png) no-repeat;
  background-size: 100% 100%;
}

.headernewbg {
  background: url(../../assets/publicmenu/menubg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
