<template>
  <div>
    <div class="fixed   right-3 top-[23rem] flex items-center z-[1000]" v-if="ishiddenbol">
      <div class="cursor-pointer" @click="ishiddenbol = false">
        <img src="../../assets/pc/rightsqimg/hidden.png" class="w-[1.5625rem] h-[5.3125rem]" alt="">
      </div>
      <div class="bgimg w-[6.1875rem] h-[23.25rem] ml-[.6875rem] flex  flex-col items-center">
        <div class="mt-[1.4375rem]">
          <img src="../../assets/wb.png" class="w-[5.1875rem] h-[5.1875rem]" alt="">
        </div>
        <span class="text mt-[.25rem]">官方微博</span>

        <div class="mt-[.2rem]">
          <img src="../../assets/wxewm.png" class="w-[5.1875rem] h-[5.1875rem]" alt="">
        </div>
        <span class="text mt-[.25rem]">官方公众号</span>

        <div class="mt-[.2rem]">
          <img src="../../assets/qqewm.png" class="w-[5.1875rem] h-[5.1875rem]" alt="">
        </div>
        <span class="text mt-[.25rem]">官方QQ</span>
      </div>
    </div>
    <div class="fixed right-0 top-[25.5rem] cursor-pointer z-[1000]" @click="ishiddenbol = true" v-if="!ishiddenbol">
      <img src="../../assets/pc/rightsqimg/show.png" class="w-[3.0625rem] h-[11.125rem]" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'rightFixed',
  data() {
    return {
      ishiddenbol: true
    }
  },
  components: {

  }
}
</script>

<style scoped>
.text {
  /* width: 62px;
  height: 15px; */
  font-size: .875rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: #FFFFFF;
  /* line-height: 1.8125rem; */
}

.bgimg {
  background: url(../../assets/pc/rightsqimg/bgimg.png) no-repeat;
  background-size: 100% 100%;
}
</style>
