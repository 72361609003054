var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed top-0 headernewbg w-full h-[4.25rem] z-[1000]"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"flex items-center h-full absolute left-[82.0625rem] cursor-pointer",on:{"click":function($event){_vm.isgamelist = !_vm.isgamelist}}},[_c('span',{staticClass:"titlezt flex items-center text-[1rem] hover:text-[#EE5647] namehover",class:_vm.isgamelist ? 'text-[#EE5647]' : 'text-[#FFFFFF]'},[_vm._v(" 明日世界游戏 "),_c('div',{staticClass:"w-[.75rem] h-[.8125rem] ml-[.25rem]",class:_vm.isgamelist ? 'iconbghover' : 'iconbg'})])]),(_vm.isgamelist)?_c('div',{staticClass:"flex justify-start flex-col items-start w-[14.75rem] absolute left-[79.625rem] top-[4.25rem] gamelistbg"},[_vm._m(5),_c('div',{staticClass:"mt-[1.875rem]"},_vm._l((_vm.gamelist),function(item){return _c('div',{staticClass:"ml-[3.125rem] mb-[1.125rem]"},[_c('span',{staticClass:"gamelistnamme text-[.9375rem] opacity-[0.6] text-[#FFFFFF] cursor-pointer hover:text-[#D24536]"},[_c('a',{attrs:{"href":item.path,"target":"_blank"}},[_vm._v(_vm._s(item.gamename))])])])}),0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute left-[24.1875rem] h-full flex items-center"},[_c('a',{attrs:{"href":"https://game.nextjoy.com/","target":"_blank"}},[_c('img',{staticClass:"w-[8.5625rem] h-[2rem]",attrs:{"src":require("../../assets/publicmenu/logo.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute left-[33.25rem] h-full flex items-center"},[_c('img',{staticClass:"w-[.0625rem] h-[1.625rem]",attrs:{"src":require("../../assets/publicmenu/hr1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex absolute left-[34.4375rem] h-full items-center"},[_c('a',{attrs:{"href":"https://mrzx-bbs.bjnextworld.com/pc/","target":"_blank"}},[_c('img',{staticClass:"w-[9.375rem] h-[2.125rem]",attrs:{"src":require("../../assets/publicmenu/sqname.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center h-full absolute left-[58.6875rem] cursor-pointer"},[_c('span',{staticClass:"text-[.9375rem] font-[100] font-[italic] text-[#FFFFFF] hover:text-[#e78b83] titlezt"},[_c('a',{attrs:{"href":"https://game.nextjoy.com/","target":"_blank"}},[_vm._v("明日世界游戏")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center h-full absolute left-[70.375rem] cursor-pointer"},[_c('span',{staticClass:"titlezt text-[1rem] text-[#FFFFFF] hover:text-[#EE5647]"},[_c('a',{attrs:{"href":"https://mrzx-bbs.bjnextworld.com/pc/","target":"_blank"}},[_vm._v("官方社区")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center ml-[2.4375rem] mt-[2.3125rem]"},[_c('img',{staticClass:"w-[.125rem] h-[1.4375rem]",attrs:{"src":require("../../assets/publicmenu/hr.png"),"alt":""}}),_c('span',{staticClass:"text-[#FFFFFF] text-[1.25rem] ml-[.625rem] gamelisttitle"},[_vm._v("手机游戏")])])
}]

export { render, staticRenderFns }