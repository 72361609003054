<template>
  <div class="bg-[rgb(22,24,25)] h-[100vh] relative">
    <menupage></menupage>
    <div class="absolute top-[4.625rem] left-[21.625rem]" @click="onbackfun()">
      <img src="../../../assets/mobile/news/back.png" class="w-[1rem] h-[1.125rem]" alt="">
    </div>
    <div class="w-full h-[25.3125rem] z-[1]">
      <img src="../../../assets/mobile/news/headbg.png" class="w-full h-[25.3125rem]" alt="">
    </div>
    
    <!-- 内容 -->
    <div
      class="w-full h-[26.8125rem] bg-[rgb(22,24,25)] absolute  top-[14.8125rem] flex justify-center flex-col items-center clearfix  z-[2]">
      <!--  分页组件 -->
    <div class="w-[12.5rem] mx-auto mt-[.9375rem] absolute bottom-0" v-if="fybol">
      <van-pagination v-model="selectpage" :total-items="50" :show-page-size="5" style="height: 1.4375rem;" @change="onfyfun()">
        <template #prev-text>
          <span
            class="text-[#ffffff] text-[.5rem] w-[2.1875rem] h-[1.4375rem] flex justify-center items-center">上一页</span>
          <!-- <van-icon name="arrow-left" /> -->
        </template>
        <template #next-text>
          <span
            class="text-[#ffffff] text-[.5rem] w-[2.1875rem] h-[1.4375rem] flex justify-center items-center">下一页</span>
        </template>
        <template #page="{ text }">
          <span class="text-[.5rem] flex justify-center items-center">{{ text }}</span></template>
      </van-pagination>
    </div>
      <!-- 新闻标题 -->
      <div class="w-full flex justify-center mt-[2rem] ">
        <div class="flex gap-[2.3125rem]">
          <div v-for="(news, index) in newsList" class="flex cursor-pointer" @click="onnewsfun(news, index)">
            <p class="mewstitle" :class="index == menuindex ? 'text-[#E1C695]' : 'text-[#ffffff]'">{{ news.name }}</p>
          </div>
        </div>
      </div>
      <!-- 分割线 -->
      <div class="flex justify-center">
        <hr class="menuhr mt-[.875rem]"/>
      </div>

      <!-- 内容 -->
      <div class="bg-[rgb(22,24,25)] w-full flex justify-center back">
        <div class="h-[23.5rem] mt-[.875rem] bg-[rgb(22,24,25)]">
          <div v-for="item in newscontent" class="mt-[.625rem] cursor-pointer">
            <div class="flex justify-between" @click="shownewstext(item)">
              <!-- 标题 -->
              <div class="newstitle w-[11.375rem] overflow-hidden whitespace-nowrap text-ellipsis">{{ item.title }}</div>
              <div class="newstitledate"> {{ item.start_time }}</div>
            </div>
            <!-- 分割线 -->
            <div class="w-[20.25rem] h-[.0625rem] bg-[#797772] opacity-[0.64] mt-[.9375rem]"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menupage from '../../../components/mobile/menupage.vue'
import axios from 'axios';
export default {
  name: 'newsIndex',
  data() {
    return {
      newscontent: [
        {
          name: '《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        },
        {
          name: '《战之刃：幸存者》燃情内测返利公告',
          date: '6/26'
        }
      ],
      menuindex: 0,
      newsList: [
        {
          name: '最新'
        },
        {
          name: '新闻'
        },
        {
          name: '公告'
        },
        {
          name: '活动'
        },
      ],
      selectindex: 0,
      page: '1',
      total: '',
      page_total: '',
      fybol: false,
      selectpage:undefined,
      selcttype:undefined
    }
  },
  components: {
    menupage
  },
  created() {
    this.getinit()
  },
  methods: {
    setType(index) {
      let type;
      if (index == 1) {
        type = 50;
      } else if (index == 2) {
        type = 51;
      } else if (index == 3) {
        type = 52;
      }
      return type;
    },
    getinit() {
      axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
        params: {
          pubkey: "3253942b24c7ad7eca566bf92696e1a6",
          page: 1,
          limit: '10',
          // type_id:'50'
        }
      }).then(res => {
        this.newscontent = res.data.data.time_news.slice(0, 8)
        this.total = ''
        this.page_total = ''
        this.fybol = false
      })
    },
    onfyfun(){
      axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
        params: {
          pubkey: "3253942b24c7ad7eca566bf92696e1a6",
          page: this.selectpage,
          limit: '8',
          type_id: this.selcttype
        }
      }).then(res => {
        this.newscontent = ''
        this.newscontent = res.data.data.news.data
        // this.total = res.data.data.news.total
        // this.page_total = res.data.data.news.page_total
        // if (this.page_total == '1') {
        //   this.fybol = false
        // } else {
        //   this.fybol = true
        // }
      })
    },
    onbackfun() {
      this.$router.push({
        name: 'indexpage', query: {
          menuid: '1',
          merchID:this.$route.query.merchID,
          subchannel:this.$route.query.subchannel,
        }
      })
    },
    // 新闻跳转
    shownewstext(item) {
      this.$router.push({
        name: 'newscontext', query: {
          article_id: item.id,
          merchID:this.$route.query.merchID,
          subchannel:this.$route.query.subchannel,
        }
      })
      console.log(item)
    },

    onnewsfun(item, index) {
      this.selcttype = this.setType(index)
      console.log('onnewsfun')
      this.menuindex = index
      // let pubkey = '3253942b24c7ad7eca566bf92696e1a6'
      // let page = this.page
      if (index == 0) {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: 1,
            limit: '10',
            // type_id:'50'
          }
        }).then(res => {
          this.newscontent = res.data.data.time_news.slice(0, 8)
          this.total = ''
          this.page_total = ''
          this.fybol = false
        })
      } else {
        axios.get('https://sdk.nextjoy.com/api/information/GetIndex', {
          params: {
            pubkey: "3253942b24c7ad7eca566bf92696e1a6",
            page: this.page,
            limit: '8',
            type_id: this.setType(index)
            // type_id:'50'
          }
        }).then(res => {
          console.log('回调方法', res)
          this.newscontent = res.data.data.news.data
          this.total = res.data.data.news.total
          this.page_total = res.data.data.news.page_total
          if (this.page_total == 1) {
            this.fybol = false
          } else {
            this.fybol = true
          }
        })
      }
    },
  }
}
</script>
<style>
/* 上一页 */
.van-pagination .van-pagination__prev {
  background: rgb(32, 35, 36);
}

/* 下一页 */
.van-pagination .van-pagination__next {
  background: rgb(32, 35, 36);
}

.van-pagination .van-pagination__item {
  color: #ADA79D;
}

/* 上一页下一页置灰选中 */
.van-pagination .van-pagination__item--disabled:active {
  background: rgba(71, 75, 78, 0.6)!important;
  color: #fff;
}

/* 上一页下一页常量选中 */
.van-pagination .van-pagination__item:active {
  background: rgba(71, 75, 78, 0.6)!important;
  color: #fff;

}

.van-pagination .van-pagination__item--active {
  background-color: rgb(71, 75, 78)!important;
  color: #fff;
  /* background: rgb(71, 75, 78) ; */
}

.van-pagination .van-pagination__item {
  background-color: rgb(32, 35, 36);
  min-width: 1.4375rem;
  height: 1.4375rem;
  /* color: red; */
}

/* .van-pagination .van-pagination__page{
  color: aliceblue;
}
.van-pagination .van-pagination__item{
  background-color: #fff;
} */
</style>
<style scoped>
.clearfix::before {
  content: '';
  display: table;
}

.newstitledate {
  /* width: 32px;
  height: 8px; */
  font-size: .625rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: #FFFFFF;
  /* line-height: 21px; */
}

.newstitle {
  /* width: 182px;
  height: 10px; */
  font-size: .625rem;
  font-family: Source Han Serif SC;
  font-weight: 300;
  color: #FFFFFF;
  /* line-height: 15px; */
}

.menuhr {
  width: 22.25rem;
  height: .0625rem;
  background: #797772 ;
  border: .0625rem solid #797772;
}

.mewstitle {
  /* width: 30px; */
  /* height: 14px; */
  font-size: .875rem;
  font-family: Source Han Serif SC;
  font-weight: 800;
  /* color: #FFFFFF; */
  /* line-height: 17px; */
}
</style>
